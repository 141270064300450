import { useObservableState } from 'observable-hooks';
import defaultState from './default';
import facade from './facade';

export default function useUIState() {
   return {
      state: new Proxy(
         {},
         {
            /* eslint-disable react-hooks/rules-of-hooks */
            get: (target, prop) => {
               if (prop === 'error') {
                  return useObservableState(facade.query.selectError());
               }
               if (prop === 'isLoading') {
                  return useObservableState(facade.loading$, true);
               }

               return useObservableState(facade.query.select(prop), defaultState[prop]);
            },
            /* eslint-enable react-hooks/rules-of-hooks */
         }
      ),
      query: facade.query,
      updateUIState: facade.updateUIState,
      toggleUIState: facade.toggleUIState,
      batchUpdateUI: facade.batchUpdateUI,
   };
}
