import { subject } from '@casl/ability';
import ability from 'casl/ability';

export function determineDefaultEntryPath() {
   if (!ability) {
      return '403';
   }
   if (ability.can('read', 'chart') && ability.can('read', subject('overviewTab', { userId: -1 }))) {
      return 'dashboard/overview';
   }
   if (ability.can('read', 'kpiArea')) {
      return 'kpi';
   }
   if (ability.can('read', subject('businessActivity', {}))) {
      return 'taxonomy';
   }
   if (ability.can('read', subject('footprint', {}))) {
      return 'emissions/ghg';
   }
   if (ability.can('read', 'report')) {
      return 'reports';
   }
   if (ability.can('read', 'task')) {
      return 'tasks';
   }
   return 'users/me';
}

export function determineDashboardEntryPath() {
   if (!ability) {
      return '403';
   }
   if (ability.can('read', 'chart') && ability.can('read', subject('overviewTab', { userId: -1 }))) {
      return 'dashboard/overview';
   }
   if (ability.can('read', 'kpiArea')) {
      return 'dashboard/overview';
   }
   if (ability.can('read', subject('businessActivity', {}))) {
      return 'taxonomy';
   }
   if (ability.can('read', subject('footprint', {}))) {
      return 'dashboard/status';
   } // TODO change if we have a dedicated dashboard
   return 'dashboard/status';
}
