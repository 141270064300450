import { DefaultReportEditor } from 'components/ReportEditor/DefaultReportEditor';
import useOrganisationContext from 'context/OrganisationContext/hook';
import DOMPurify from 'dompurify';
import { groupBy, max, mean, merge, min, omit, padStart, pick, size, sortBy, sum } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import useKPIFieldsFacade from 'state/KPIField/hook';
import useKPISubsFacade from 'state/KPISub/hook';
import useKPIValuesFacade from 'state/KPIValue/hook';
import useReportDocumentsFacade from 'state/ReportDocument/hook';
import useReportDocumentTemplatesFacade from 'state/ReportDocumentTemplates/hook';
import { formatNumber, formatOptions, mergeFormatOptions } from 'utils';
import { KPIFieldType, KPIValueType } from 'utils/enum';
import useUserContext from '../../context/UserContext/hook';

const aggregationFunctionMapping = {
   sum: sum,
   min: min,
   max: max,
   avg: mean,
   size: size,
};

/**
 * A custom React hook that manages the configuration of a CKEditor instance.
 * It takes into account the default configuration, the user's language setting, and any additional configuration from props.config.
 * It dynamically updates the editor's configuration based on the user's language, the current period context, and the active organisation.
 *
 * @param {Object} props - Contains the initial configuration properties for the editor, including any overrides.
 * @param {Function} onMention - Callback function to handle the mention feature logic. This should be the {@link ReportEditorService#onMention} function.
 * @param {Object} htmlRefs - An object with properties where the values are React.MutableRefObject instances. These references are used to manage UI components related to the editor. Expected properties:
 *                            - `sidebarElementRef`: Refers to the sidebar container element.
 *                            - `presenceListElementRef`: Refers to the presence list element.
 *                            - `editorContainerRef`: Refers to the main editor container element.
 *                            - `viewerContainerElementRef`: Refers to the container element of the viewer mode.
 *                            - `viewerEditorElementRef`: Refers to the editor element in viewer mode.
 *                            - `viewerSidebarContainerElementRef`: Refers to the sidebar container element in viewer mode.
 * @returns {Object} An object containing the updated configuration for the CKEditor instance (`config`) and a dynamically generated unique identifier for the editor instance (`id`).
 */
export const useSetConfig = (props, onMention, htmlRefs) => {
   const intl = useIntl();
   const {
      state: { currencyCode },
   } = useOrganisationContext();

   const defaultValuesByType = useMemo(
      () => ({
         TEXT: intl.formatMessage({
            id: 'reportEditor.mergeFields.placeholder',
            defaultMessage: 'Placeholder',
         }),
         SELECT: intl.formatMessage({
            id: 'reportEditor.mergeFields.selectedOption',
            defaultMessage: 'Selected Option',
         }),
         TEXTAREA:
            'Your work on this project has been really impactful you gotta smoke test your hypothesis, yet you better eat a reality sandwich before you walk back in that boardroom.',
         NUMBER: intl.formatNumber(1234.56).toString(),
         PERCENTAGE: intl.formatNumber(Math.random(), { style: 'percent' }),
         FORMULA: intl.formatNumber(1234.56),
         DATE: intl.formatDate(new Date(), { dateStyle: 'medium' }),
         SURFACE: `${intl.formatNumber(1234.56)} m²`,
         ELECTRICITY: `${intl.formatNumber(1234.56)} kWh`,
         WEIGHT: `${intl.formatNumber(1234.56)} kg`,
         MEASUREMENT: `${intl.formatNumber(1234.56)} m`,
         VOLUME: `${intl.formatNumber(1234.56)} l`,
         EMISSIONS: `${intl.formatNumber(1234.56)} kg CO²`,
         EMISSION_SELECT: `${intl.formatNumber(1234.56)} kg CO²`,
         SWITCH: intl.formatMessage({
            id: 'reportEditor.mergeFields.true',
            defaultMessage: 'Yes',
         }),
         PRICE: intl.formatNumber(1234.56, { style: 'currency', currency: currencyCode ?? 'EUR', currencyDisplay: 'code' }),
      }),
      [currencyCode, intl]
   );

   const {
      state: { supportedLanguage: userLanguage, reportEditorMergeFieldsPreviewMode, locale },
   } = useUserContext();
   const {
      state: { activeDocumentId, activeEntity: activeReportDocument },
   } = useReportDocumentsFacade();

   const {
      state: { entities: kpiFields, entitiesTableColumns: tableColumns },
   } = useKPIFieldsFacade();

   const {
      state: { allEntities: kpiSubs },
   } = useKPISubsFacade();

   const {
      state: { reportData, isLoadingReportData },
   } = useKPIValuesFacade();

   const {
      state: { entities: reportDocumentTemplates, isLoading: isLoadingReportDocumentTemplates },
   } = useReportDocumentTemplatesFacade();

   const documentFormat = useMemo(() => new Intl.NumberFormat(locale ?? 'en-US'), [locale]);

   const defaultFormatOptions = useMemo(() => pick(documentFormat.resolvedOptions(), formatOptions), [documentFormat]);

   const formatValue = useCallback(
      (value, unit, dataType, kpiFieldType, format = {}, items = []) => {
         let formattedValue;

         let style = 'decimal';

         if (unit === '%') {
            style = 'percent';
         } else if (unit) {
            style = 'unit';
         }

         if (items.length > 0) {
            return items.find(({ id }) => id === value)?.name;
         }

         switch (dataType) {
            case KPIValueType.NUMBER:
            case KPIValueType.DECIMAL:
               formattedValue = formatNumber(intl, value, unit, mergeFormatOptions({ ...format, style }, defaultFormatOptions));
               break;
            case KPIValueType.DATE:
               formattedValue = intl.formatDate(value, { dateStyle: 'medium' });
               break;
            case KPIValueType.BOOLEAN:
               formattedValue = intl.formatMessage({
                  id: `reportEditor.mergeFields.${value.toString()}`,
                  defaultMessage: value.toString(),
               });
               break;
            case KPIValueType.TEXT:
               formattedValue = (value ?? '').replace(/\n/g, '<br/>');
               break;
            default:
               formattedValue = value;
               break;
         }

         return formattedValue;
      },
      [defaultFormatOptions, intl]
   );

   const relevantReportingStandardIds = useMemo(
      () => (activeReportDocument?.report?.standards ? activeReportDocument.report.standards.map(({ id }) => id) : []),
      [activeReportDocument]
   );

   const envoriaTables = useMemo(
      () => ({
         tables: sortBy(
            Object.entries(
               groupBy(
                  sortBy(
                     kpiFields.filter(({ type }) => type === KPIFieldType.TABLE),
                     (kpiField) => {
                        const kpiSub = kpiSubs.find(({ id }) => id === kpiField.kpiContent.kpiSub.id);

                        if (kpiSub.kpi?.kpiArea) {
                           const groupString = Number.parseInt(
                              `${padStart(kpiSub.kpi.kpiArea.position, 4, 0)}${padStart(kpiSub.kpi.position, 4, 0)}${padStart(kpiSub.position, 4, 0)}`
                           );
                           return groupString;
                        } else {
                           return `00000000${padStart(kpiSub.position, 4, 0)}`;
                        }
                     }
                  ).map(({ identifier, name, columns, rows, columnName, kpiContent }) => ({
                     kpiContent,
                     id: identifier,
                     label: name,
                     columnName,
                     rows: rows.map(({ name, slug }) => ({ identifier: `${identifier}_${slug}`, name })),
                     columns: columns.map(({ id }) => {
                        const column = tableColumns.find(({ id: columnId }) => columnId === id);

                        if (column) {
                           const { identifier, name, aggregation, type } = column;

                           return { identifier, name, aggregation, type };
                        }
                     }),
                  })),
                  (o) => o.kpiContent.kpiSub.id
               )
            ).map(([groupId, tableFields]) => {
               const kpiSub = kpiSubs.find(({ id }) => id === Number.parseInt(groupId, 10));

               return {
                  groupId,
                  groupLabel: kpiSub?.key ? `[${kpiSub.key.replace(/[\s‑]/g, '-')}] ${kpiSub.name}` : kpiSub?.name,
                  itemDefinitions: tableFields,
               };
            }),
            ({ groupId }) => {
               const kpiSub = kpiSubs.find(({ id }) => id === Number.parseInt(groupId, 10));

               const groupString = Number.parseInt(
                  `${padStart(kpiSub.kpi.kpiArea.position, 4, 0)}${padStart(kpiSub.kpi.position, 4, 0)}${padStart(kpiSub.position, 4, 0)}`
               );

               return groupString;
            }
         ),
      }),
      [kpiFields, kpiSubs, tableColumns]
   );

   const template = useMemo(
      () => ({
         definitions: reportDocumentTemplates.map(({ name: title, desc: description, data }) => ({
            title,
            description,
            data,
         })),
      }),
      [reportDocumentTemplates]
   );

   const values = useMemo(
      () =>
         reportData.reduce((valueObj, kpiValue) => {
            const relevantField = kpiFields.find(({ id }) => id === kpiValue.kpiFieldId);

            const formattedValue = formatValue(
               kpiValue.value,
               kpiValue.unit,
               kpiValue.dataType,
               relevantField?.kpiFieldType?.name,
               relevantField?.format,
               relevantField?.items
            );

            const identifier = (kpiValue.rowIdentifier ?? kpiValue.identifier).replace(/[\s‑]/g, '-');

            if (relevantField?.kpiFieldType?.name === KPIFieldType.SWITCH && kpiValue.justification) {
               return {
                  ...valueObj,
                  [identifier.concat('_justification')]: kpiValue.justification,
                  [identifier]: formattedValue,
               };
            }

            if (kpiValue.attributes?.length > 0) {
               return kpiValue.attributes.reduce((subValueObj, attribute) => {
                  const cellIdentifier = identifier.concat('_').concat(attribute.identifier);

                  const formattedCellValue = formatValue(
                     attribute.value,
                     attribute.unit,
                     attribute.dataType,
                     relevantField?.kpiFieldType?.name,
                     relevantField?.format,
                     relevantField?.items
                  );

                  if (subValueObj[cellIdentifier]) {
                     return {
                        ...subValueObj,
                        [cellIdentifier]: formattedCellValue + subValueObj[cellIdentifier],
                     };
                  } else {
                     return {
                        ...subValueObj,
                        [cellIdentifier]: formattedCellValue,
                     };
                  }
               }, valueObj);
            } else {
               return {
                  ...valueObj,
                  [identifier]: formattedValue,
               };
            }
         }, {}),
      [formatValue, kpiFields, reportData]
   );

   const initialPreviewMode = useMemo(() => reportEditorMergeFieldsPreviewMode ?? '$labels', [reportEditorMergeFieldsPreviewMode]);

   const regularDefinitions = useMemo(
      () =>
         sortBy(
            Object.entries(
               groupBy(
                  kpiFields.filter(
                     ({ reportingStandard, kpiContent, scope, fieldGroupId }) =>
                        !fieldGroupId && scope !== false && relevantReportingStandardIds.includes(reportingStandard?.id) && kpiContent?.kpiSub
                  ),
                  (o) => o.kpiContent.kpiSub.id
               )
            ),
            ([groupId]) => {
               const kpiSub = kpiSubs.find(({ id }) => id === Number.parseInt(groupId, 10));

               const groupString = Number.parseInt(
                  `${padStart(kpiSub.kpi.kpiArea.position, 4, 0)}${padStart(kpiSub.kpi.position, 4, 0)}${padStart(kpiSub.position, 4, 0)}`
               );

               return groupString;
            }
         ).map(([groupId, theFields]) => {
            const kpiSub = kpiSubs.find(({ id }) => id === Number.parseInt(groupId, 10));

            return {
               groupId,
               groupLabel: kpiSub?.key ? `[${kpiSub.key.replace(/[\s‑]/g, '-')}] ${kpiSub.name}` : kpiSub?.name,
               definitions: sortBy(theFields, (o) => `${o.kpiContent.position}${o.position}`).flatMap(
                  ({ identifier, name: label, type, rows, columns }) => {
                     if (type === KPIFieldType.TABLE) {
                        return columns.flatMap(({ type: colType, name: colName, identifier: colIdentifier }) =>
                           rows.map(({ name: rowName, slug: rowSlug }) => ({
                              id: identifier.replace(/[\s‑]/g, '-').concat('_').concat(rowSlug).concat('_').concat(colIdentifier),
                              label: `${label} - ${colName} - ${rowName}`,
                              defaultValue: defaultValuesByType[colType],
                              type: 'text',
                           }))
                        );
                     } else if (type === KPIFieldType.SWITCH) {
                        return [
                           {
                              id: identifier.replace(/[\s‑]/g, '-'),
                              label,
                              defaultValue: defaultValuesByType[type],
                              type: 'text',
                           },
                           {
                              id: identifier.replace(/[\s‑]/g, '-').concat('_justification'),
                              label: label
                                 .concat(' ')
                                 .concat(intl.formatMessage({ id: 'reportEditor.mergeFields.justification', defaultMessage: '(Justification)' })),
                              defaultValue: defaultValuesByType[type],
                              type: 'text',
                           },
                        ];
                     } else {
                        return {
                           id: identifier.replace(/[\s‑]/g, '-'),
                           label,
                           defaultValue: defaultValuesByType[type],
                           type: type === KPIFieldType.TEXTAREA ? 'block' : 'text',
                        };
                     }
                  }
               ),
            };
         }),
      [intl, defaultValuesByType, kpiFields, kpiSubs, relevantReportingStandardIds]
   );

   const totalDefinitions = useMemo(
      () =>
         Object.entries(
            groupBy(
               kpiFields.flatMap(({ kpiContent, name: label, identifier: tableIdentifier, columns }) =>
                  columns
                     .filter(({ aggregation }) => kpiContent.kpiSub?.id && aggregation)
                     .map(({ identifier, type, name: colName }) => ({
                        groupId: kpiContent.kpiSub.id.toString(),
                        id: (tableIdentifier ?? '')
                           .replace(/[\s‑]/g, '-')
                           .concat('_')
                           .concat('AGG')
                           .concat('_')
                           .concat(identifier.replace(/[\s‑]/g, '-')),
                        label: `${label} - ${colName} - Total`,
                        defaultValue: defaultValuesByType[type],
                        type: 'text',
                     }))
               ),
               'groupId'
            )
         ).map(([groupId, theFields]) => {
            const kpiSub = kpiSubs.find(({ id }) => id === Number.parseInt(groupId, 10));

            return {
               groupId,
               groupLabel: kpiSub?.key ? `[${kpiSub.key.replace(/[\s‑]/g, '-')}] ${kpiSub.name}` : kpiSub?.name,
               definitions: theFields,
            };
         }),
      [defaultValuesByType, kpiFields, kpiSubs]
   );

   const aggregationValues = useMemo(
      () =>
         merge(
            ...kpiFields.flatMap(({ identifier: tableIdentifier, columns, rows }) =>
               columns
                  .filter(({ aggregation }) => aggregation)
                  .flatMap((column) => {
                     let identifier;

                     if (['sum_row', 'relation'].includes(column.aggregation)) {
                        let totalSum = 0;

                        if (column.aggregation === 'relation') {
                           totalSum = Object.entries(values)
                              .filter(([valueIdentifier]) => valueIdentifier.startsWith(tableIdentifier.concat('_')))
                              .map(([, value]) => Number.parseFloat(value))
                              .filter((value) => !Number.isNaN(value))
                              .reduce((sum, value) => sum + value, 0);
                        }

                        const returnArray = [];

                        rows.forEach((row) => {
                           identifier = tableIdentifier.concat('_').concat(row.slug.replace(/[\s‑]/g, '-')).concat('_');

                           const rowSum = Object.entries(values)
                              .filter(([valueIdentifier]) => valueIdentifier.startsWith(identifier))
                              .map(([, value]) => Number.parseFloat(value))
                              .filter((value) => !Number.isNaN(value))
                              .reduce((sum, value) => sum + value, 0);

                           if (column.aggregation !== 'relation') {
                              totalSum += rowSum;
                           }

                           returnArray.push({
                              [identifier.concat(column.identifier.replace(/[\s‑]/g, '-'))]:
                                 column.aggregation === 'relation'
                                    ? intl.formatNumber((totalSum === 0 ? 1 : rowSum) / (totalSum === 0 ? rows.length : totalSum), {
                                         style: 'percent',
                                      })
                                    : intl.formatNumber(rowSum),
                           });
                        });

                        returnArray.push({
                           [tableIdentifier.concat('_').concat('AGG').concat('_').concat(column.identifier.replace(/[\s‑]/g, '-'))]:
                              intl.formatNumber(column.aggregation === 'relation' ? 1 : totalSum, {
                                 style: column.aggregation === 'relation' ? 'percent' : 'decimal',
                              }),
                        });

                        return returnArray;
                     } else {
                        identifier = column.identifier.replace(/[\s‑]/g, '-');

                        const value = aggregationFunctionMapping[column.aggregation](
                           Object.entries(values)
                              .filter(
                                 ([valueIdentifier]) =>
                                    valueIdentifier.startsWith(tableIdentifier.concat('_')) && valueIdentifier.endsWith(identifier)
                              )
                              .map(([, value]) => Number.parseFloat(value))
                              .filter((value) => !Number.isNaN(value))
                        );

                        return {
                           [tableIdentifier.concat('_').concat('AGG').concat('_').concat(identifier)]: intl.formatNumber(value),
                        };
                     }
                  })
            )
         ),
      [intl, kpiFields, values]
   );

   const definitions = useMemo(
      () =>
         regularDefinitions.map((def) => ({
            ...def,
            definitions: def.definitions.concat(totalDefinitions.find(({ groupId }) => groupId === def.groupId)?.definitions ?? []),
         })),
      [regularDefinitions, totalDefinitions]
   );

   const mergeFields = useMemo(
      () => ({
         initialPreviewMode,
         previewHtmlValues: true,
         sanitizeHtml(inputHtml) {
            if (/<[a-z/][\s\S]*>/i.test(inputHtml)) {
               return {
                  html: DOMPurify.sanitize(inputHtml, { ALLOWED_TAGS: ['br', 'p', 'span'] }),
                  hasChanged: true,
               };
            }

            return {
               html: inputHtml,
               hasChanged: false,
            };
         },
         previewModes: ['$labels', '$dataSets'],
         dataSets: [
            {
               id: 'kpiValues',
               label: intl.formatMessage({ id: 'reportEditor.mergeFields.values', defaultMessage: 'Values' }),
               values: merge(values, aggregationValues),
            },
         ],
         definitions,
      }),
      [initialPreviewMode, intl, values, aggregationValues, definitions]
   );

   const {
      editorContainerRef,
      editorPresenceRef,
      editorOutlineRef,
      editorAnnotationsRef,
      editorRevisionHistoryRef,
      editorRevisionHistoryEditorRef,
      editorRevisionHistorySidebarRef,
   } = htmlRefs;

   const specificConfig = useMemo(
      () => ({
         envoriaTables,
         language: userLanguage,
         comments: {
            editorConfig: {
               mention: {
                  dropDownLimit: 5,
                  feeds: [
                     {
                        marker: '@',
                        feed: (queryText) => onMention(queryText),
                        minimumCharacters: 3,
                     },
                  ],
               },
            },
         },
         presenceList: {
            container: editorPresenceRef.current,
         },
         revisionHistory: {
            editorContainer: editorContainerRef.current,
            viewerContainer: editorRevisionHistoryRef.current,
            viewerEditorElement: editorRevisionHistoryEditorRef.current,
            viewerSidebarContainer: editorRevisionHistorySidebarRef.current,
            resumeUnsavedRevision: true,
         },
         sidebar: {
            container: editorAnnotationsRef.current,
         },
         documentOutline: {
            container: editorOutlineRef.current,
         },
         template,
         collaboration: {
            channelId: activeDocumentId,
         },
         exportWord: {
            fileName: `${activeReportDocument?.name}.docx`,
         },
         mergeFields,
      }),
      [
         activeDocumentId,
         activeReportDocument?.name,
         editorAnnotationsRef,
         editorContainerRef,
         editorOutlineRef,
         editorPresenceRef,
         editorRevisionHistoryEditorRef,
         editorRevisionHistoryRef,
         editorRevisionHistorySidebarRef,
         envoriaTables,
         mergeFields,
         onMention,
         template,
         userLanguage,
      ]
   );

   const editorsConfig = useMemo(
      () => merge(omit(DefaultReportEditor.defaultConfig, ['mergeFields']), props.config ?? {}, specificConfig),
      [props.config, specificConfig]
   );

   return {
      config: editorsConfig,
      isLoading:
         (isLoadingReportData ?? true) || (isLoadingReportDocumentTemplates ?? true) || !activeDocumentId || mergeFields.definitions.length === 0,
   };
};
