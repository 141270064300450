import { subject } from '@casl/ability';
import { arrayMove } from '@dnd-kit/sortable';
import { Box, Tooltip, Typography } from '@mui/material';
import ability from 'casl/ability';
import Icon from 'components/Icon';
import { isValid, isWithinInterval, parseISO } from 'date-fns';
import { get, isObject, orderBy, uniq } from 'lodash-es';
import type { IntlShape } from 'react-intl';
import { ApprovalStatus, KPIFieldType } from 'utils/enum';
import { getAuditStatusColor } from './colors';
import { getAuditStatusIcon } from './icons';

type KPIFieldTypeKeys = (typeof KPIFieldType)[keyof typeof KPIFieldType];

export function fieldTypeRequiresUnit(kpiFieldType: KPIFieldTypeKeys): boolean {
   const unitRequiringFields = [
      KPIFieldType.WEIGHT,
      KPIFieldType.SURFACE,
      KPIFieldType.MEASUREMENT,
      KPIFieldType.EMISSIONS,
      KPIFieldType.EMISSION_SELECT,
      KPIFieldType.DISTANCE,
      KPIFieldType.ELECTRICITY,
      KPIFieldType.VOLUME,
      KPIFieldType.WOOD,
      KPIFieldType.TIME,
      KPIFieldType.PERCENTAGE,
   ] as const;
   return (unitRequiringFields as readonly KPIFieldTypeKeys[]).includes(kpiFieldType);
}

export function fieldTypeAllowsUnit(kpiFieldType: keyof typeof KPIFieldType): boolean {
   return fieldTypeRequiresUnit(kpiFieldType) || kpiFieldType === KPIFieldType.FORMULA;
}

export function fieldTypeIsNumeric(kpiFieldType: KPIFieldTypeKeys): boolean {
   const numericFields = [
      KPIFieldType.WEIGHT,
      KPIFieldType.SURFACE,
      KPIFieldType.PRICE,
      KPIFieldType.MEASUREMENT,
      KPIFieldType.EMISSIONS,
      KPIFieldType.EMISSION_SELECT,
      KPIFieldType.DISTANCE,
      KPIFieldType.ELECTRICITY,
      KPIFieldType.VOLUME,
      KPIFieldType.PERCENTAGE,
      KPIFieldType.NUMBER,
      KPIFieldType.DATA,
      KPIFieldType.FORMULA,
      KPIFieldType.PASSENGER,
      KPIFieldType.WOOD,
      KPIFieldType.TIME,
   ] as const;
   return (numericFields as readonly KPIFieldTypeKeys[]).includes(kpiFieldType);
}

export function canAccessOrganisation(organisationId: string) {
   return ability.can('read', subject('organisation', { organisationId }));
}

export async function handleDrag(active: any, over: any): Promise<void> {
   if (active?.data?.current?.type && Array.isArray(over?.data?.current?.accepts) && over.data.current.accepts.includes(active.data.current.type)) {
      let parentKey: string | undefined;
      let collectionName: string | undefined;
      let parentEntityName: string | undefined;

      switch (active.data.current.type) {
         case 'footprintSub':
            parentKey = 'footprintId';
            collectionName = 'subs';
            parentEntityName = 'emissions/ghg/footprints';
            break;
         case 'fpCalculation':
            parentKey = 'footprintSubId';
            collectionName = 'calculations';
            parentEntityName = 'emissions/ghg/footprintssubs';
            break;
         case 'fpCalculationField':
            parentKey = 'fpCalculationId';
            collectionName = 'fields';
            parentEntityName = 'emissions/ghg/calculations';
            break;
         case 'kpi':
            parentKey = 'kpiAreaId';
            collectionName = 'kpis';
            parentEntityName = 'kpiAreas';
            break;
         case 'kpiSub':
            parentKey = 'kpiId';
            collectionName = 'kpisubs';
            parentEntityName = 'kpis';
            break;
         case 'kpiContent':
            parentKey = 'kpiSubId';
            collectionName = 'kpicontents';
            parentEntityName = 'kpisubs';
            break;
         case 'kpiField':
            parentKey = 'kpiContentId';
            collectionName = 'kpifields';
            parentEntityName = 'kpicontents';
            break;
         default:
            break;
      }

      // rearrange in the same container
      if (active.id !== over.id && active.data.current.sortable.containerId === over.data.current.sortable.containerId) {
         const oldIndex = active.data.current.sortable.items.indexOf(active.id);
         const newIndex = over.data.current.sortable.items.indexOf(over.id);

         active.data.current.api.sortEntities(
            arrayMove(active.data.current.sortable.items, oldIndex, newIndex),
            parentEntityName!,
            `${active.data.current.type}Id` === parentKey ? active.id : active.data.current.sortable.containerId,
            collectionName!
         );
         // move to a different container
      } else if (active.data.current.sortable.containerId !== over.data.current.sortable.containerId) {
         await active.data.current.api.updateEntity(active.id, {
            [parentKey!]: `${over.data.current.type}Id` === parentKey ? over.id : over.data.current.sortable.containerId,
         });
         over.data.current.api.sortEntities(
            over.data.current.sortable.items.splice(over.data.current.sortable.index, 0, active.id),
            parentEntityName!,
            `${over.data.current.type}Id` === parentKey ? over.id : over.data.current.sortable.containerId,
            collectionName!
         );
         active.data.current.api.sortEntities(
            active.data.current.sortable.items.splice(active.data.current.sortable.items.indexOf(active.id), 1),
            parentEntityName!,
            `${active.data.current.type}Id` === parentKey ? active.id : active.data.current.sortable.containerId,
            collectionName!
         );
      }
   }
}

type Parent = {
   validFrom?: string; // ISO date string
   validTo?: string; // ISO date string
   [key: string]: any; // Allow additional properties if needed
};

type Organisation = {
   parents?: Parent[]; // Optional array of Parent objects
   [key: string]: any; // Allow additional properties if needed
};

type Period = {
   from?: string; // ISO date string
   to?: string; // ISO date string
};

// Define the return type for the function
type OrgParentWithValidation = Parent & {
   isValidFromInPeriod: boolean;
   isValidToInPeriod: boolean;
   validFromEqualOrBefore: boolean;
   validToEqualAfterPeriod: boolean;
   notValidForPeriod: boolean;
   noValidationDateValidFrom: boolean;
   noValidationDateValidTo: boolean;
};

export function getCorrectOrganisationParent(organisation: Organisation, period: Period): OrgParentWithValidation[] {
   const parentValidPeriod = (organisation.parents ?? []).flatMap((_parent) => [_parent?.validFrom, _parent?.validTo]);

   if (parentValidPeriod.length === 0) {
      return [];
   }

   if (period?.from && period?.to) {
      const currentPeriodFrom = parseISO(period.from);
      const currentPeriodTo = parseISO(period.to);

      if (parentValidPeriod.length > 0) {
         const checkIfParentIsInPeriod: OrgParentWithValidation[] = [];

         (organisation.parents ?? []).forEach((parent) => {
            let isValidFromInPeriod = false;
            let isValidToInPeriod = false;
            let validFromEqualOrBefore = false;
            let validToEqualAfterPeriod = false;
            let noValidationDateValidFrom = false;
            let noValidationDateValidTo = false;
            let notValidForPeriod = false;

            const from = parent?.validFrom && isValid(parseISO(parent?.validFrom)) ? parseISO(parent.validFrom) : null;
            const to = parent?.validTo && isValid(parseISO(parent?.validTo)) ? parseISO(parent.validTo) : null;

            if (!from || !to) {
               noValidationDateValidFrom = from === null;
               noValidationDateValidTo = to === null;
            }

            if (from) {
               validFromEqualOrBefore = from <= currentPeriodFrom;
               isValidFromInPeriod = isWithinInterval(from, {
                  start: currentPeriodFrom,
                  end: currentPeriodTo,
               });
               if (!validFromEqualOrBefore && !isValidFromInPeriod && !to) {
                  notValidForPeriod = true;
               }
            }

            if (to) {
               validToEqualAfterPeriod = to >= currentPeriodTo;
               isValidToInPeriod = isWithinInterval(to, {
                  start: currentPeriodFrom,
                  end: currentPeriodTo,
               });
               if (!validToEqualAfterPeriod && !isValidToInPeriod && !from) {
                  notValidForPeriod = true;
               }
            }

            if (
               ((!validToEqualAfterPeriod && !isValidToInPeriod) || (!validFromEqualOrBefore && !isValidFromInPeriod)) &&
               !noValidationDateValidFrom &&
               !noValidationDateValidTo
            ) {
               notValidForPeriod = true;
            }

            checkIfParentIsInPeriod.push({
               ...parent,
               isValidFromInPeriod,
               isValidToInPeriod,
               validFromEqualOrBefore,
               validToEqualAfterPeriod,
               notValidForPeriod,
               noValidationDateValidFrom,
               noValidationDateValidTo,
            });
         });

         return checkIfParentIsInPeriod;
      }
   }

   return [];
}

interface OrganisationObject {
   id?: string;
   code?: string;
   name?: string;
}

export function formatOrganisationName(
   organisation: Organisation,
   period: Period,
   intl: IntlShape,
   organisations: OrganisationObject[],
   viewType: string,
   showCode = true
) {
   if ((!period?.from && !period?.to) || organisations.length === 0) {
      return '';
   }
   if ((organisation?.parents ?? []).length === 0) {
      return organisation.code && showCode ? `${organisation.code}: ${organisation.name}` : organisation.name;
   }
   const organisationLabel = organisation.code && showCode ? `${organisation.code}: ${organisation.name}` : `${organisation.name}`;

   const parentsPerPeriod = getCorrectOrganisationParent(organisation, period);

   const parentsValidForPeriod = parentsPerPeriod.filter(
      ({
         validFromEqualOrBefore,
         validToEqualAfterPeriod,
         noValidationDateValidFrom,
         isValidFromInPeriod,
         noValidationDateValidTo,
         isValidToInPeriod,
      }) =>
         (validFromEqualOrBefore || noValidationDateValidFrom || isValidFromInPeriod) &&
         (validToEqualAfterPeriod || noValidationDateValidTo || isValidToInPeriod)
   );

   if (parentsValidForPeriod.length === 1) {
      const {
         isValidFromInPeriod,
         isValidToInPeriod,
         validFromEqualOrBefore,
         validToEqualAfterPeriod,
         noValidationDateValidFrom,
         noValidationDateValidTo,
         validFrom,
         validTo,
         subsidiaryProportion,
      } = parentsValidForPeriod[0];

      const formattedProportion = intl.formatNumber(subsidiaryProportion ?? 0, {
         maximumFractionDigits: 5,
         style: 'percent',
      });

      if ((validFromEqualOrBefore || noValidationDateValidFrom) && (validToEqualAfterPeriod || noValidationDateValidTo)) {
         let formattedLabel = `${formattedProportion}`;
         if (viewType === 'row') {
            formattedLabel = `${organisationLabel} (${formattedProportion})`;
         }
         return <Typography> {formattedLabel} </Typography>;
      } else if (isValidFromInPeriod || isValidToInPeriod) {
         let validText = '';
         if (noValidationDateValidTo && isValidFromInPeriod) {
            validText = `${intl.formatMessage({
               id: 'organisations.validFrom',
               defaultMessage: 'valid from:',
            })} ${parseISO(validFrom!).toLocaleDateString()}`;
         } else if (isValidToInPeriod && noValidationDateValidFrom) {
            validText = `${intl.formatMessage({
               id: 'organisations.validTo',
               defaultMessage: 'valid to:',
            })} ${parseISO(validTo!).toLocaleDateString()}`;
         } else {
            validText = `${parseISO(validFrom!).toLocaleDateString()} - ${parseISO(validTo!).toLocaleDateString()}`;
         }
         return (
            <Typography>
               {viewType === 'row' ? organisationLabel : ''}
               {`${formattedProportion} (${validText})`}
            </Typography>
         );
      }
   } else {
      let parentsArray = parentsValidForPeriod;
      if (parentsValidForPeriod.length === 0 && parentsPerPeriod.length > 0) {
         parentsArray = parentsPerPeriod;
      }

      parentsArray = orderBy(parentsArray, ['validFrom', 'validTo'], ['asc', 'asc']);
      const uniqParentIds = uniq(Array.from(parentsArray.map((parent) => parent?.id)));

      return (
         <>
            {viewType === 'row' && (
               <Typography paddingBottom={parentsArray.length > 0 ? 1 : 0} fontSize={14}>
                  {organisationLabel}
               </Typography>
            )}
            {(parentsArray ?? []).map((parent, index) => {
               const {
                  validFromEqualOrBefore,
                  validToEqualAfterPeriod,
                  noValidationDateValidFrom,
                  noValidationDateValidTo,
                  validFrom,
                  validTo,
                  isValidFromInPeriod,
                  isValidToInPeriod,
                  notValidForPeriod,
               } = parent;

               let parentName = '';
               if (uniqParentIds.length > 1) {
                  const parentObject = (organisations ?? []).find((org) => org?.id === parent?.id);
                  parentName = parentObject?.code && showCode ? `${parentObject?.code}: ${parentObject?.name}` : `${parentObject?.name}`;
               }
               const fromattedProportion = intl.formatNumber(parent?.subsidiaryProportion ?? 0, {
                  maximumFractionDigits: 2,
                  style: 'percent',
               });

               if ((validFromEqualOrBefore || noValidationDateValidFrom) && (validToEqualAfterPeriod || noValidationDateValidTo)) {
                  return (
                     <Typography paddingBottom={1} fontSize={12} key={`${parent?.id}_${index}`}>
                        {`${fromattedProportion} (${parentName})`}
                     </Typography>
                  );
               } else if (isValidFromInPeriod || isValidToInPeriod || notValidForPeriod) {
                  let validText = '';
                  if ((noValidationDateValidTo && isValidFromInPeriod) || (noValidationDateValidTo && notValidForPeriod)) {
                     validText = `${intl.formatMessage({
                        id: 'organisations.validFrom',
                        defaultMessage: 'valid from:',
                     })} ${parseISO(validFrom!).toLocaleDateString()}`;
                  } else if ((noValidationDateValidFrom && isValidToInPeriod) || (noValidationDateValidFrom && notValidForPeriod)) {
                     validText = `${intl.formatMessage({
                        id: 'organisations.validTo',
                        defaultMessage: 'valid to:',
                     })} ${parseISO(validTo!).toLocaleDateString()}`;
                  } else {
                     validText = `${parseISO(validFrom!).toLocaleDateString()} - ${parseISO(validTo!).toLocaleDateString()}`;
                  }

                  const formattedProportionPeriod = `${fromattedProportion} (${validText}) ${parentName}`;
                  return (
                     <Typography paddingBottom={1} fontSize={12} key={`${parent?.id}_${index}`}>
                        {formattedProportionPeriod}
                     </Typography>
                  );
               }
            })}
         </>
      );
   }
}

type User = {
   id: string; // Assuming `id` is a string; adjust if necessary
};

type Group = {
   users: User[];
};

type TransformInput = {
   allowedAuditors?: User[];
   allowedEditors?: User[];
   allowedAuditorGroups?: Group[];
   allowedEditorGroups?: Group[];
   [key: string]: unknown; // Allow additional properties if necessary
};

type TransformOutput = Omit<TransformInput, 'allowedAuditors' | 'allowedEditors' | 'allowedAuditorGroups' | 'allowedEditorGroups'> & {
   allowedAuditors: string[]; // IDs only
   allowedEditors: string[];
   allowedAuditorGroups: string[]; // Flattened group IDs
   allowedEditorGroups: string[];
};

// Fully typed function
export function transformAllowedAuditorsAndEditorsToIds(object: TransformInput): TransformOutput {
   const allowedAuditors = (object?.allowedAuditors ?? []).map((auditor) => auditor.id) ?? [];
   const allowedEditors = (object?.allowedEditors ?? []).map((editor) => editor.id) ?? [];
   const allowedAuditorGroups = (object?.allowedAuditorGroups ?? []).flatMap((group) => group?.users.map((auditor) => auditor.id)) ?? [];
   const allowedEditorGroups = (object?.allowedEditorGroups ?? []).flatMap((group) => group?.users.map((editor) => editor.id)) ?? [];

   return {
      ...object,
      allowedAuditors: [...allowedAuditors, ...allowedAuditorGroups],
      allowedEditors: [...allowedEditors, ...allowedEditorGroups],
      allowedAuditorGroups,
      allowedEditorGroups,
   };
}

export function determineAuditStatusTooltipTitle(intl: IntlShape, object: any) {
   if (object?.status === ApprovalStatus.APPROVED && object?.approver && object?.reviewer) {
      return intl.formatMessage(
         {
            id: 'common.approvedBy',
            defaultMessage: 'Status: {status} {br} Approved by {approver} {br} Reviewed by {reviewer}',
         },
         {
            approver: `${object?.approver?.firstName} ${object?.approver?.lastName}`,
            reviewer: `${object?.reviewer?.firstName} ${object?.reviewer?.lastName}`,
            br: <br />,
            status: intl.formatMessage({
               id: `overview.tabs.status.${object?.status}`,
               defaultMessage: (object?.status ?? '').toLowerCase(),
            }),
         }
      );
   } else if (object?.status === ApprovalStatus.REVIEWED && object?.reviewer) {
      return intl.formatMessage(
         {
            id: 'common.reviewedBy',
            defaultMessage: 'Status: {status} {br} Reviewed by {reviewer}',
         },
         {
            reviewer: `${object?.reviewer?.firstName} ${object?.reviewer?.lastName}`,
            br: <br />,
            status: intl.formatMessage({
               id: `overview.tabs.status.${object?.status}`,
               defaultMessage: (object?.status ?? '').toLowerCase(),
            }),
         }
      );
   } else {
      return intl.formatMessage(
         {
            id: `overview.tabs.status.tooltip`,
            defaultMessage: 'Status: {status}',
         },
         {
            status: intl.formatMessage({
               id: `overview.tabs.status.${object?.status}`,
               defaultMessage: (object?.status ?? '').toLowerCase(),
            }),
         }
      );
   }
}

export function getAuditStatusNode(intl: IntlShape, palette: any, object: any, fontSize = 20) {
   return (
      <Tooltip title={determineAuditStatusTooltipTitle(intl, object)}>
         <Box component="span" display="inline-flex">
            <Icon icon={getAuditStatusIcon(object?.status)} fontSize={fontSize} sx={{ color: get(palette, getAuditStatusColor(object?.status)) }} />
         </Box>
      </Tooltip>
   );
}

interface ErrorObject {
   error?: string;
   kind?: string;
   [key: string]: any;
}

export function formatErrors(errorList: ErrorObject[], intl: IntlShape) {
   const sanitizedErrorList = errorList.filter((err) => isObject(err));

   if (sanitizedErrorList.length === 0) {
      return null;
   }

   return (
      <>
         <Typography variant="inherit">
            {intl.formatMessage({ id: 'errors.conversionTitle', defaultMessage: 'Errors occurred while calculating:' })}
         </Typography>
         {sanitizedErrorList.map((errObj, index) => (
            <Typography variant="inherit" key={index}>{`- ${intl.formatMessage(
               { id: `errors.${errObj?.error ?? 'unknown_error'}`, defaultMessage: errObj?.error ?? '' },
               {
                  ...errObj,
                  ...(errObj?.kind
                     ? {
                          kind: intl.formatMessage({
                             id: `entityKind.${errObj?.kind}`,
                             defaultMessage: errObj?.kind,
                          }),
                       }
                     : {}),
               }
            )}`}</Typography>
         ))}
      </>
   );
}
