import { activeEntity$, activeEntityId$, allEntities$, entities$, loading$, loadingProgress$, query, scopedEntities$ } from './query';
import KPISubsService from './service';

const api = new KPISubsService();

const facade = {
   api,
   query,
   entities$,
   activeEntity$,
   allEntities$,
   scopedEntities$,
   loading$,
   activeEntityId$,
   loadingProgress$,
};

export default facade;
