import { applyTransaction } from '@datorama/akita';
import { query } from 'state/UI/query';
import store from 'state/UI/store';

export function toggleUIState(prop) {
   store.update((state) => ({ [prop]: !state[prop] }));
}

export function updateUIState(prop, value) {
   if (query.getValue()[prop] !== value) {store.update({ [prop]: value });}
}

export function batchUpdateUI(updates = []) {
   applyTransaction(() => {
      updates.forEach(([prop, value] = []) => prop && value && updateUIState(prop, value));
   });
}
