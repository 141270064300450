import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { omit } from 'lodash-es';
import { map } from 'rxjs';
import { entities$ as kpiSet$ } from 'state/KPISet/query';
import { query as uiQuery } from 'state/UI/query';

import { activeOrganisationId$, subsidiaryIds$ } from 'context/OrganisationContext/query';
import { includeSubsidiaries$ } from 'context/UserContext/query';
import { compareAsc, parseISO } from 'date-fns';
import { entities$ as organisations$ } from 'state/Organisations/query';
import store from './store';

export const query = createEntityQuery(store, {
   sortBy: 'updatedAt',
   sortByOrder: Order.DESC,
});

// we want to have the newest element (highest ID) first, but for un-synced items (local state), we want them to appear on top
// e.g. -3,-2,-1,0,999,998,997,996 etc.
const sortBy = (a, b) => (a.id <= 0 ? a.id - b.id : b.id - a.id);

export const entities$ = query.selectAll({ sortBy });

export const entitiesWithFlattenedItems$ = entities$.pipe(
   map((kpiValueOrValueItems) =>
      kpiValueOrValueItems.flatMap((kpiValueOrItem) =>
         kpiValueOrItem?.items?.length > 0
            ? kpiValueOrItem.items
                 .slice()
                 .map((item) => ({
                    ...item,
                    kpiValueId: kpiValueOrItem.id,
                    kpiValueItemId: item.id,
                    kpiFieldId: kpiValueOrItem?.kpiFieldId,
                    fpCalculationField: kpiValueOrItem?.fpCalculationField,
                    organisationId: kpiValueOrItem.organisationId,
                    date: (item.attributes ?? []).length === 0 ? parseISO(item.date) : null,
                    to: (item.attributes ?? []).length > 0 ? parseISO(kpiValueOrItem.to) : null,
                    from: parseISO(kpiValueOrItem.from),
                    objectType: 'kpiValueItem',
                    createdAt: parseISO(item.createdAt),
                    updatedAt: parseISO(item.updatedAt),
                    currency: kpiValueOrItem?.currency,
                    unit: kpiValueOrItem?.unit,
                 }))
                 .sort((a, b) => compareAsc(a?.date, b?.date))
            : {
                 ...omit(kpiValueOrItem, [
                    'originalValue',
                    'originalUnit',
                    'originalCurrency',
                    'approvedAt',
                    'reviewedAt',
                    'archivedAt',
                    'deletedAt',
                 ]),
                 kpiValueId: kpiValueOrItem.id,
                 from: parseISO(kpiValueOrItem.from),
                 to: parseISO(kpiValueOrItem.to),
                 createdAt: parseISO(kpiValueOrItem.createdAt),
                 updatedAt: parseISO(kpiValueOrItem.updatedAt),
                 value: kpiValueOrItem?.originalValue ?? kpiValueOrItem.value,
                 unit: kpiValueOrItem?.originalUnit ?? kpiValueOrItem.unit,
                 currency: kpiValueOrItem?.originalCurrency ?? kpiValueOrItem.currency,
                 objectType: 'kpiValue',
                 isPredefined: false,
                 kpiValueItemId: null,
                 attributes: [],
              }
      )
   )
);

export const activeEntity$ = query.selectActive();

export const reportData$ = query.select('reportData');
export const loadingReportData$ = query.select('loadingReportData');

export const report$ = combineQueries([
   kpiSet$,
   query.select('sums'),
   uiQuery.select(['keyFigureReportReportingStandardIds', 'reportShowEmptyFields', 'reportGroupByOrganisation']),
   organisations$,
   includeSubsidiaries$,
   subsidiaryIds$,
   activeOrganisationId$,
]).pipe(
   map(
      ([
         kpiSet,
         sums,
         { keyFigureReportReportingStandardIds, reportShowEmptyFields: showEmptyFields, reportGroupByOrganisation },
         organisations,
         includeSubsidiaries,
         subsidiaryIds,
         activeOrganisationId,
      ]) =>
         kpiSet
            .filter(
               (kpiArea) =>
                  (keyFigureReportReportingStandardIds.length === 0 || keyFigureReportReportingStandardIds.includes(kpiArea.reportingStandard.id)) &&
                  (showEmptyFields
                     ? true
                     : kpiArea.kpis.some((kpi) =>
                          kpi.kpiSubs.some((kpiSub) =>
                             kpiSub.kpiContents.some((kpiContent) =>
                                kpiContent.kpiFields.some((kpiField) =>
                                   sums.some(
                                      (kpiValue) =>
                                         (kpiField.id === kpiValue.kpiFieldId && kpiValue.value !== null) ||
                                         (kpiValue?.attributes ?? []).some(
                                            (attr) =>
                                               attr.value !== null &&
                                               [kpiField.id, ...(kpiField?.columns ?? []).map(({ id }) => id)].includes(attr.kpiFieldId)
                                         )
                                   )
                                )
                             )
                          )
                       ))
            )
            .map((kpiArea) => ({
               kind: 'kpiArea',
               identifier: kpiArea.slug,
               ...omit(kpiArea, ['slug', 'kpiScopes', 'reportingStandard']),
               kpis: Array.isArray(kpiArea?.kpis)
                  ? kpiArea.kpis
                       .filter(
                          (kpi) =>
                             (keyFigureReportReportingStandardIds.length === 0 ||
                                keyFigureReportReportingStandardIds.includes(kpi.reportingStandard.id)) &&
                             (showEmptyFields
                                ? true
                                : kpi.kpiSubs.some((kpiSub) =>
                                     kpiSub.kpiContents.some((kpiContent) =>
                                        kpiContent.kpiFields.some((kpiField) =>
                                           sums.some(
                                              (kpiValue) =>
                                                 (kpiField.id === kpiValue.kpiFieldId && kpiValue.value !== null) ||
                                                 (kpiValue?.attributes ?? []).some(
                                                    (attr) =>
                                                       attr.value !== null &&
                                                       [kpiField.id, ...(kpiField?.columns ?? []).map(({ id }) => id)].includes(attr.kpiFieldId)
                                                 )
                                           )
                                        )
                                     )
                                  ))
                       )
                       .map((kpi) => ({
                          kind: 'kpi',
                          identifier: kpi.slug,
                          ...omit(kpi, ['slug', 'kpiScopes', 'reportingStandard']),
                          kpiSubs: Array.isArray(kpi?.kpiSubs)
                             ? kpi.kpiSubs
                                  .filter(
                                     (kpiSub) =>
                                        (keyFigureReportReportingStandardIds.length === 0 ||
                                           keyFigureReportReportingStandardIds.includes(kpiSub.reportingStandard.id)) &&
                                        (showEmptyFields
                                           ? true
                                           : kpiSub.kpiContents.some((kpiContent) =>
                                                kpiContent.kpiFields.some((kpiField) =>
                                                   sums.some(
                                                      (kpiValue) =>
                                                         (kpiField.id === kpiValue.kpiFieldId && kpiValue.value !== null) ||
                                                         (kpiValue?.attributes ?? []).some(
                                                            (attr) =>
                                                               attr.value !== null &&
                                                               [kpiField.id, ...(kpiField?.columns ?? []).map(({ id }) => id)].includes(
                                                                  attr.kpiFieldId
                                                               )
                                                         )
                                                   )
                                                )
                                             ))
                                  )
                                  .map((kpiSub) => ({
                                     kind: 'kpiSub',
                                     identifier: kpiSub.slug,
                                     ...omit(kpiSub, ['slug', 'kpiScopes', 'reportingStandard']),
                                     kpiContents: Array.isArray(kpiSub?.kpiContents)
                                        ? kpiSub.kpiContents
                                             .filter(
                                                (kpiContent) =>
                                                   (keyFigureReportReportingStandardIds.length === 0 ||
                                                      keyFigureReportReportingStandardIds.includes(kpiContent.reportingStandard.id)) &&
                                                   (showEmptyFields
                                                      ? true
                                                      : kpiContent.kpiFields.some((kpiField) =>
                                                           sums.some(
                                                              (kpiValue) =>
                                                                 (kpiField.id === kpiValue.kpiFieldId && kpiValue.value !== null) ||
                                                                 (kpiValue?.attributes ?? []).some(
                                                                    (attr) =>
                                                                       attr.value !== null &&
                                                                       [kpiField.id, ...(kpiField?.columns ?? []).map(({ id }) => id)].includes(
                                                                          attr.kpiFieldId
                                                                       )
                                                                 )
                                                           )
                                                        ))
                                             )
                                             .map((kpiContent) => ({
                                                kind: 'kpiContent',
                                                identifier: kpiContent.slug,
                                                ...omit(kpiContent, ['slug', 'kpiScopes', 'reportingStandard']),
                                                kpiFields: Array.isArray(kpiContent?.kpiFields)
                                                   ? kpiContent.kpiFields
                                                        .filter(
                                                           (kpiField) =>
                                                              !kpiField?.fieldGroupId &&
                                                              (keyFigureReportReportingStandardIds.length === 0 ||
                                                                 keyFigureReportReportingStandardIds.includes(kpiField.reportingStandard.id)) &&
                                                              (showEmptyFields
                                                                 ? true
                                                                 : sums.some(
                                                                      (kpiValue) =>
                                                                         (kpiField.id === kpiValue.kpiFieldId && kpiValue.value !== null) ||
                                                                         (kpiValue?.attributes ?? []).some(
                                                                            (attr) =>
                                                                               attr.value !== null &&
                                                                               [
                                                                                  kpiField.id,
                                                                                  ...(kpiField?.columns ?? []).map(({ id }) => id),
                                                                               ].includes(attr.kpiFieldId)
                                                                         )
                                                                   ))
                                                        )
                                                        .map((kpiField) => ({
                                                           kind: 'kpiField',
                                                           identifier: kpiField.slug,
                                                           ...omit(kpiField, [
                                                              'id',
                                                              'placeholder',
                                                              'allowEditValue',
                                                              'allowAddOnDataChildOrganisations',
                                                              'dataEntryOrganisations',
                                                              'forbiddenAllKpiFieldItemMeta',
                                                              'canAttachFile',
                                                              'mustAttachFile',
                                                              'isPredefined',
                                                              'kpiScopes',
                                                              'reportingStandard',
                                                              'kpiFieldType',
                                                              'allowedAuditors',
                                                              'items',
                                                              'kpiTargets',
                                                              'slug',
                                                           ]),
                                                           rows: (kpiField?.rows ?? []).map((row) => ({
                                                              ...row,
                                                              sums: showEmptyFields
                                                                 ? organisations
                                                                      .filter((organisation) =>
                                                                         reportGroupByOrganisation && includeSubsidiaries
                                                                            ? subsidiaryIds.includes(organisation.id) ||
                                                                              organisation.id === activeOrganisationId
                                                                            : organisation.id === activeOrganisationId
                                                                      )
                                                                      .flatMap((organisation) =>
                                                                         (
                                                                            sums.find(
                                                                               (kpiValue) =>
                                                                                  kpiValue.rowId === row.id &&
                                                                                  (kpiValue?.attributes ?? []).some(
                                                                                     (attr) => attr.organisationId === organisation.id
                                                                                  )
                                                                            )?.attributes ?? []
                                                                         )
                                                                            .filter((kpiValue) => kpiValue.organisationId === organisation.id)
                                                                            .map((kpiValue) => ({
                                                                               organisationId: organisation.id,
                                                                               ...kpiValue,
                                                                            }))
                                                                      )
                                                                 : sums
                                                                      .filter(
                                                                         (kpiValue) =>
                                                                            kpiValue.rowId === row.id &&
                                                                            (kpiValue?.attributes ?? []).some((attr) => attr.value !== null)
                                                                      )
                                                                      .flatMap((kpiValue) => kpiValue?.attributes ?? []),
                                                           })),
                                                           sums: showEmptyFields
                                                              ? organisations
                                                                   .filter((organisation) =>
                                                                      reportGroupByOrganisation && includeSubsidiaries
                                                                         ? subsidiaryIds.includes(organisation.id) ||
                                                                           organisation.id === activeOrganisationId
                                                                         : organisation.id === activeOrganisationId
                                                                   )
                                                                   .map((organisation) => ({
                                                                      organisationId: organisation.id,
                                                                      ...sums.find(
                                                                         (kpiValue) =>
                                                                            kpiValue.kpiFieldId === kpiField.id &&
                                                                            kpiValue.organisationId === organisation.id
                                                                      ),
                                                                   }))
                                                              : sums.filter(
                                                                   (kpiValue) => kpiValue.kpiFieldId === kpiField.id && kpiValue.value !== null
                                                                ),
                                                        }))
                                                   : [],
                                             }))
                                        : [],
                                  }))
                             : [],
                       }))
                  : [],
            }))
   )
);

export const loading$ = query.selectLoading();
