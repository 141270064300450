import { merge, omit } from 'lodash-es';
import type { FormatNumberOptions, IntlShape } from 'react-intl';
import regionCodeMapping from 'resources/json/RegionCodes.json';
import sanctionedUnits from 'resources/json/sanctionedUnits.json';

export * from './intl.lang';
export * from './intl.units';

export function formatRegionCode(intl: IntlShape, code: keyof typeof regionCodeMapping) {
   let formattedCode;

   let [sanitizedCode] = code.split('-');
   [sanitizedCode] = sanitizedCode.split('_');

   if (sanitizedCode === 'UK') {
      sanitizedCode = 'GB';
   }

   const regularIntl = new Intl.DisplayNames([intl.locale], { type: 'region', style: 'long' });

   try {
      regularIntl.of(sanitizedCode);

      formattedCode = intl.formatDisplayName(sanitizedCode, {
         type: 'region',
         style: 'long',
      });
   } catch {
      formattedCode = intl.formatMessage({
         id: `emissions.regions.${code}`,
         defaultMessage: regionCodeMapping?.[code] ?? code,
      });
   }

   return formattedCode;
}

interface CustomFormatNumberOptions extends FormatNumberOptions {
   prefix?: string;
   suffix?: string;
}

export function mergeFormatOptions(specificFormatOptions: CustomFormatNumberOptions = {}, defaultFormatOptions: CustomFormatNumberOptions = {}) {
   const attributesToSkip = [];

   if (
      specificFormatOptions?.minimumFractionDigits !== defaultFormatOptions?.minimumFractionDigits ||
      specificFormatOptions?.maximumFractionDigits !== defaultFormatOptions?.maximumFractionDigits
   ) {
      attributesToSkip.push('minimumSignificantDigits');
      attributesToSkip.push('maximumSignificantDigits');
   }

   if (
      specificFormatOptions?.minimumSignificantDigits !== defaultFormatOptions?.minimumSignificantDigits ||
      specificFormatOptions?.maximumSignificantDigits !== defaultFormatOptions?.maximumSignificantDigits
   ) {
      attributesToSkip.push('minimumFractionDigits');
      attributesToSkip.push('maximumFractionDigits');
   }

   const specificFormatOptionsSanitized = Object.fromEntries(Object.entries(specificFormatOptions ?? {}).filter(([, value]) => value !== null));

   return merge(omit(defaultFormatOptions, [...attributesToSkip, ...Object.keys(specificFormatOptionsSanitized)]), specificFormatOptionsSanitized);
}

export function getDecimalSeparator(locale: Intl.LocalesArgument) {
   const number = 1.1;

   return (
      Intl.NumberFormat(locale)
         .formatToParts(number)
         .find((part) => part.type === 'decimal')?.value ?? ''
   );
}

export function getGroupingSeparator(locale: Intl.LocalesArgument) {
   const number = 10000;

   return (
      Intl.NumberFormat(locale)
         .formatToParts(number)
         .find((part) => part.type === 'group')?.value ?? ''
   );
}

export function formatNumber(intl: IntlShape, value: number, unit: string, formatOptions: CustomFormatNumberOptions) {
   const prefix = `${formatOptions?.prefix ?? ''}`;
   const _value = `${intl.formatNumber(formatOptions?.style === 'percent' ? value / 100 : value, {
      ...omit(formatOptions, ['prefix', 'suffix', 'style', 'unitDisplay', 'minimumSignificantDigits', 'maximumSignificantDigits']),
      style: !sanctionedUnits.includes(unit) && formatOptions?.style === 'unit' ? 'decimal' : formatOptions?.style,
      ...(sanctionedUnits.includes(unit) && formatOptions?.unitDisplay ? { unitDisplay: formatOptions?.unitDisplay } : {}),
   })}`;
   const separator = `${formatOptions?.style === 'unit' && unit && !sanctionedUnits.includes(unit) && formatOptions?.unitDisplay === 'short' ? ' ' : ''}`;
   const _unit = `${formatOptions?.style === 'unit' && unit && !sanctionedUnits.includes(unit) ? unit : ''}`;
   const suffix = `${formatOptions?.suffix ?? ''}`;

   return `${prefix}${_value}${separator}${_unit}${suffix}`;
}
