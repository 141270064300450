import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { isEqual } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { activeEntityId$ as activeKPIId$ } from 'state/KPI/query';
import { flatScopedEntitiesResult$ } from 'state/KPISet/query';
import store from './store';

const theQuery = createEntityQuery(store, {
   sortBy: 'position',
   sortByOrder: Order.ASC,
});

export const query = theQuery;

export const allEntities$ = query.selectAll();

export const activeEntityId$ = query.selectActiveId();

export const entities$ = combineQueries([allEntities$, activeKPIId$, flatScopedEntitiesResult$]).pipe(
   map(([kpiSubs, activeKPIId, scopingResults]) =>
      activeKPIId
         ? kpiSubs
              .filter((kpiSub) => kpiSub?.kpi?.id === Number.parseInt(activeKPIId, 10))
              .map((kpiSub) => ({
                 ...scopingResults.find((result) => result.type === 'kpiSub' && result.id === kpiSub.id),
                 ...kpiSub,
              }))
         : []
   ),
   distinctUntilChanged(isEqual)
);

export const scopedEntities$ = combineQueries([allEntities$, flatScopedEntitiesResult$]).pipe(
   map(([kpiSubs, scopingResults]) =>
      kpiSubs.map((kpiSub) => ({
         ...scopingResults.find((result) => result.type === 'kpiSub' && result.id === kpiSub.id),
         ...kpiSub,
      }))
   ),
   distinctUntilChanged(isEqual)
);

export const activeEntity$ = combineQueries([theQuery.selectActive(), flatScopedEntitiesResult$]).pipe(
   map(([activeKPISub, scopingResults]) => ({
      ...scopingResults.find((result) => result.type === 'kpiSub' && result.id === activeKPISub?.id),
      ...activeKPISub,
   }))
);

export const loading$ = query.selectLoading();
export const loadingProgress$ = query.select('isLoadingProgress');
