import { has, isEmpty } from 'lodash-es';

type DirtyFields = boolean | Array<any> | Record<string, any>;

export function getDirtyFormValues(dirtyFields: DirtyFields, formValues: Record<string, any>): any {
   if (dirtyFields === true) {
      return formValues;
   }

   if (Array.isArray(dirtyFields)) {
      return dirtyFields
         .map((dirtyField, index) => getDirtyFormValues(dirtyField, formValues?.[index]))
         .filter((value) => {
            if (value && Object.getPrototypeOf(value) === Object.prototype) {
               return !isEmpty(value);
            }
            if (Array.isArray(value)) {
               return value.filter((val) => !isEmpty(val));
            }
            return true;
         });
   }

   return Object.fromEntries(
      Object.entries(dirtyFields)
         .filter(([key, isDirty]) => formValues && has(formValues, key) && isDirty !== false && !(Array.isArray(isDirty) && isDirty.length === 0))
         .map(([key, value]) => [key, getDirtyFormValues(value, formValues[key])])
         .filter(([, value]) => {
            if (value && Object.getPrototypeOf(value) === Object.prototype) {
               return !isEmpty(value);
            }
            if (Array.isArray(value)) {
               return value.filter((val) => !isEmpty(val));
            }
            return true;
         })
   );
}
