import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EnvoriaLoadingIcon from 'components/v2/EnvoriaLoadingIcon';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useKPIValuesFacade from 'state/KPIValue/hook';
import useReportDocumentsFacade from 'state/ReportDocument/hook';
import useReportDocumentTemplatesFacade from 'state/ReportDocumentTemplates/hook';
import useUIState from 'state/UI/hook';
import { useReportEditorFacade } from '../../state/ReportEditor/hook';
import { DefaultReportEditor } from './DefaultReportEditor';
import './style.css';

/**
 * ReportEditor component for sustainability reporting with real-time collaborative editing.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.config - The configuration object for the editor.
 * @param {string} props.label - The label to display in the editor.
 * @param {Function} [props.onReady] - Callback function to execute when the editor is ready.
 * @param {Function} [props.onError] - Callback function to execute when there is an error in the editor.
 * @param {React.ForwardedRef} ref - The reference to the editor component.
 * @returns {React.ForwardRefExoticComponent} The rendered ReportEditor component.
 */
const ReportEditor = function ReportEditor() {
   // state
   const theme = useTheme();
   const { updateUIState } = useUIState();

   const [isLayoutReady, setIsLayoutReady] = useState(false);
   /**  uncommenting non used
    * const [isEditorReady, setIsEditorReady] = useState(false);
    * */

   // references
   const editorPresenceRef = useRef(null);
   const editorContainerRef = useRef(null);
   const editorMenuBarRef = useRef(null);
   const editorToolbarRef = useRef(null);
   const editorOutlineRef = useRef(null);
   const editorRef = useRef(null);
   const editorInstanceRef = useRef(null);
   const editorAnnotationsRef = useRef(null);
   const editorRevisionHistoryRef = useRef(null);
   const editorRevisionHistoryEditorRef = useRef(null);
   const editorRevisionHistorySidebarRef = useRef(null);

   // hooks
   const { onReady, onError, useSetConfig, onMention } = useReportEditorFacade();
   const { enqueueSnackbar } = useSnackbar();
   const { api: reportDocumentTemplatesAPI } = useReportDocumentTemplatesFacade();
   const { api: kpiValuesAPI } = useKPIValuesFacade();
   const {
      state: { activeReportId },
   } = useReportDocumentsFacade();

   // editors config (merged with default config, config.language gets overwritten by user language)
   const { config, isLoading: isConfigLoading } = useSetConfig({}, onMention, {
      editorContainerRef,
      editorPresenceRef,
      editorOutlineRef,
      editorAnnotationsRef,
      editorRevisionHistoryRef,
      editorRevisionHistoryEditorRef,
      editorRevisionHistorySidebarRef,
   });

   const refreshDisplayMode = useCallback(() => {
      if (!editorInstanceRef?.current || !editorAnnotationsRef?.current) {
         return;
      }

      const editorInstance = editorInstanceRef.current;
      const annotationsUIs = editorInstance.plugins.get('AnnotationsUIs');
      const sidebarElement = editorAnnotationsRef.current.parentElement;

      if (window.innerWidth < 1070) {
         sidebarElement.classList.remove('narrow');
         sidebarElement.classList.add('hidden');
         annotationsUIs.switchTo('inline');
      } else if (window.innerWidth < 1300) {
         sidebarElement.classList.remove('hidden');
         sidebarElement.classList.add('narrow');
         annotationsUIs.switchTo('narrowSidebar');
      } else {
         sidebarElement.classList.remove('hidden', 'narrow');
         annotationsUIs.switchTo('wideSidebar');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isLayoutReady]);

   const checkPendingActions = useCallback(
      (domEvt) => {
         if (!editorInstanceRef?.current) {
            return;
         }

         const editorInstance = editorInstanceRef.current;

         if (editorInstance.plugins.get('PendingActions').hasAny) {
            domEvt.preventDefault();
            domEvt.returnValue = true;
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isLayoutReady]
   );

   useEffect(() => {
      if (activeReportId) {
         kpiValuesAPI.getReportData(activeReportId);
      }

      // return kpiValuesAPI.unsubscribeReportData();
   }, [kpiValuesAPI, activeReportId]);

   useEffect(() => {
      reportDocumentTemplatesAPI.getEntities();
   }, [reportDocumentTemplatesAPI]);

   useEffect(() => {
      if (!isConfigLoading && !isLayoutReady) {
         setIsLayoutReady(true);
      }

      return () => setIsLayoutReady(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isConfigLoading]);

   useEffect(() => {
      window.addEventListener('resize', refreshDisplayMode);
      window.addEventListener('beforeunload', checkPendingActions);

      return () => {
         window.removeEventListener('resize', refreshDisplayMode);
         window.removeEventListener('beforeunload', checkPendingActions);
      };
   }, [checkPendingActions, refreshDisplayMode]);

   return (
      <Box component="main-container">
         <Box className="presence" paddingLeft={2} paddingY={1}>
            <Box ref={editorPresenceRef} className="presence"></Box>
         </Box>

         <Box
            className="editor-container editor-container_document-editor editor-container_include-outline editor-container_include-annotations editor-container_include-pagination"
            ref={editorContainerRef}
         >
            <Box className="editor-container__menu-bar" ref={editorMenuBarRef}></Box>
            <Box className="editor-container__toolbar" ref={editorToolbarRef}></Box>

            <Grid container direction="row" className="editor-container__editor-wrapper" width="auto">
               <Grid item className="editor-container__sidebar" flexGrow={1}>
                  <Box ref={editorOutlineRef}></Box>
               </Grid>
               <Grid item className="editor-container__editor" width="auto">
                  <Box ref={editorRef} bgcolor={isConfigLoading ? 'transparent' : theme.palette.background.paper}>
                     {isConfigLoading && (
                        <Box height="80vh">
                           <EnvoriaLoadingIcon />
                        </Box>
                     )}
                     {isLayoutReady && !isConfigLoading && (
                        <CKEditor
                           id={config.collaboration.channelId}
                           onReady={(editor) => {
                              editor.plugins.get('MergeFieldsEditing').on('change:previewMode', (_evt, _name, value) => {
                                 updateUIState('reportEditorMergeFieldsPreviewMode', value);
                              });

                              editorInstanceRef.current = editor;
                              onReady(editor);
                              if (editorToolbarRef.current.children.length === 0) {
                                 editorToolbarRef.current.appendChild(editor.ui.view.toolbar.element);
                              }
                              if (editorMenuBarRef.current.children.length === 0) {
                                 editorMenuBarRef.current.appendChild(editor.ui.view.menuBarView.element);
                              }
                           }}
                           onAfterDestroy={() => {
                              Array.from(editorToolbarRef.current.children).forEach((child) => child.remove());
                              Array.from(editorMenuBarRef.current.children).forEach((child) => child.remove());
                           }}
                           onError={(error) => onError(error, editorRef.current, enqueueSnackbar)}
                           editor={DefaultReportEditor}
                           config={config}
                        />
                     )}
                  </Box>
               </Grid>
               <Grid item className="editor-container__sidebar" flexGrow={1}>
                  <Box ref={editorAnnotationsRef}></Box>
               </Grid>
            </Grid>
         </Box>
         <Box className="revision-history" ref={editorRevisionHistoryRef}>
            <Box className="revision-history__wrapper">
               <Box className="revision-history__editor" ref={editorRevisionHistoryEditorRef}></Box>
               <Box className="revision-history__sidebar" ref={editorRevisionHistorySidebarRef}></Box>
            </Box>
         </Box>
      </Box>
   );
};

ReportEditor.propTypes = {};

export default ReportEditor;
