import { Store } from '@datorama/akita';
import { v4 as uuidv4 } from 'uuid';

interface ErrorResponse {
  code?: number;
  status?: number;
  data?: {
    code?: number;
    message?: string;
    error?: string;
  };
}

interface Error {
  response?: ErrorResponse;
  code?: string;
  message?: string;
}

export function handleError(error: Error, store: Store): void {
  if ((error?.response?.code ?? error?.response?.status) === 502) {
    return window.location.reload();
  }
  if ((error?.response?.code ?? error?.response?.status) === 401) {
    return window.location.assign(`${window.location.origin}/login`);
  }

  if (error?.code !== 'ERR_CANCELED') {
    store.setError({
      code: error?.response?.status ?? error?.response?.data?.code,
      variant: 'error',
      message: `${error?.response?.data?.message ?? error.message ?? ''} ${error?.response?.data?.error ?? ''}`,
      key: `${store?.storeName}#${uuidv4()}`,
      store: store?.storeName,
    });
  }
  return store.setLoading(false);
}