import { loading$, query, state$ } from 'state/UI/query';
import { batchUpdateUI, toggleUIState, updateUIState } from 'state/UI/service';

const facade = {
   query,
   state$,
   updateUIState,
   batchUpdateUI,
   toggleUIState,
   loading$,
};

export default facade;
