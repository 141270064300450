import { createEntityQuery } from '@datorama/akita';
import { compareAsc } from 'date-fns';
import store from 'state/UIPeriod/store';

export const query = createEntityQuery(store, {
   sortBy: (a, b) => compareAsc(a.from, b.from),
});

export const entities$ = query.selectAll();
export const activeEntity$ = query.selectActive();
export const loading$ = query.selectLoading();
