import { locales, validateLanguageCode } from 'iso-lang-codes';
import { uniq } from 'lodash-es';
import type { IntlShape, MessageDescriptor } from 'react-intl';
import LanguageCodes from 'resources/json/LanguageCodes.json';

interface Option {
   [key: string]: any;
   label?: MessageDescriptor | string;
}

export function localizeOptions(intl: IntlShape, optionsList: Option[]): Option[] {
   return optionsList.map((option) => ({
      ...option,
      ...(option?.label && typeof option.label === 'object'
         ? {
              label: intl.formatMessage({
                 id: option.label.id,
                 defaultMessage: option.label.defaultMessage,
              }),
           }
         : {}),
   }));
}

export const supportedLanguageCodes: string[] = LanguageCodes.map(({ value }) => value);

export const sortedLanguageCodes: string[] = uniq(
   Object.keys(locales())
      .map((locale) => locale.split('-')[0])
      .filter((langCode) => validateLanguageCode(langCode))
      .sort((a, b) => {
         if (!supportedLanguageCodes.includes(a) && !supportedLanguageCodes.includes(b)) {
            return a.localeCompare(b);
         } else if (supportedLanguageCodes.includes(a) && !supportedLanguageCodes.includes(b)) {
            return -1;
         } else if (!supportedLanguageCodes.includes(a) && supportedLanguageCodes.includes(b)) {
            return 1;
         } else {
            return supportedLanguageCodes.indexOf(a) - supportedLanguageCodes.indexOf(b);
         }
      })
);
