import { ApprovalStatus, TaxonomyAlignmentType, TaxonomyContributionType } from 'utils/enum';

export const SUCCESS_COLOR = 'success.main';
export const WARNING_COLOR = 'warning.main';

export function getStatusColor(status: boolean | string): string {
   switch (status) {
      case true:
      case 'APPROVED':
      case 'FINISHED':
      case 'CLOSED':
      case 'FILLED':
         return SUCCESS_COLOR;
      case false:
      case 'DELETED':
         return 'error.main';
      case 'ARCHIVED':
         return 'blueGrey[400]';
      case 'HOLD':
      case 'REVIEWED':
         return WARNING_COLOR;
      case 'REVIEW':
         return 'orange[600]';
      case 'PROGRESS':
      case 'OPEN':
      case 'UNFILLED':
      case 'IN_PROGRESS':
      case 'NO_VERIFICATION_NEEDED':
         return 'info.main';
      default:
         return 'grey[400]';
   }
}

export function getAuditStatusColor(auditStatus: keyof typeof ApprovalStatus | string): string {
   switch (auditStatus) {
      case ApprovalStatus.REVIEWED:
         return 'info.main';
      case ApprovalStatus.APPROVED:
         return SUCCESS_COLOR;
      case ApprovalStatus.ARCHIVED:
         return 'text.disabled';
      case 'UNARCHIVE':
      case 'UNAPPROVE':
      case 'UNREVIEW':
         return WARNING_COLOR;
      case ApprovalStatus.NO_VERIFICATION_NEEDED:
      case ApprovalStatus.IN_PROGRESS:
      default:
         return 'text.primary';
   }
}

export function getContributionTypeColor(contributionTypeCode: keyof typeof TaxonomyContributionType | string): string {
   switch (contributionTypeCode) {
      case TaxonomyContributionType.SUBSTANTIAL_CONTRIBUTION:
      case TaxonomyContributionType.ENABLING_ACTIVITY:
      case TaxonomyContributionType.TRANSITIONAL_ACTIVITY:
      case TaxonomyContributionType.MINIMUM_SAFEGUARDS:
         return SUCCESS_COLOR;
      case TaxonomyContributionType.NOT_QUALIFIED:
         return 'error.main';
      case 'MIXED':
         return 'success.light';
      case TaxonomyContributionType.DO_NO_SIGNIFICANT_HARM:
      default:
         return 'grey[400]';
   }
}

export function getAlignmentTypeColor(alignmentTypeCode: keyof typeof TaxonomyAlignmentType | string): string {
   switch (alignmentTypeCode) {
      case TaxonomyAlignmentType.ELIGIBLE:
         return 'info.main';
      case TaxonomyAlignmentType.ALIGNED:
         return SUCCESS_COLOR;
      case TaxonomyAlignmentType.PARTIALLY_ALIGNED:
         return 'info.main';
      case TaxonomyAlignmentType.NOT_ALIGNED:
         return 'error.main';
      case TaxonomyAlignmentType.NOT_ELIGIBLE:
      default:
         return 'grey[400]';
   }
}

type Color = `#${string}`;

export function generateColorPalette(startColor: Color, endColor: Color, numberOfColors: number): Color[] {
   function hexToRgb(hex: Color): [number, number, number] {
      const match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (!match) throw new Error(`Invalid color format: ${hex}`);
      return [parseInt(match[1], 16), parseInt(match[2], 16), parseInt(match[3], 16)];
   }

   function rgbToHex([r, g, b]: [number, number, number]): Color {
      return `#${[r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('')}`;
   }

   function interpolate(start: number, end: number, factor: number): number {
      return Math.round(start + (end - start) * factor);
   }

   const startRgb = hexToRgb(startColor);
   const endRgb = hexToRgb(endColor);

   return Array.from({ length: numberOfColors }, (_, i) => {
      const factor = i / (numberOfColors - 1); // Progress factor between 0 and 1
      const interpolatedRgb: [number, number, number] = [
         interpolate(startRgb[0], endRgb[0], factor),
         interpolate(startRgb[1], endRgb[1], factor),
         interpolate(startRgb[2], endRgb[2], factor),
      ];
      return rgbToHex(interpolatedRgb);
   });
}
