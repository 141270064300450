import { activeEntity$, entities$, entitiesWithFlattenedItems$, loading$, loadingReportData$, query, report$, reportData$ } from './query';
import KPIValuesService from './service';

const api = new KPIValuesService();

const facade = {
   api,
   query,
   entities$,
   activeEntity$,
   entitiesWithFlattenedItems$,
   loading$,
   reportData$,
   report$,
   loadingReportData$,
};

export default facade;
