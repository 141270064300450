import { Typography, styled } from '@mui/material';
import { SnackbarContent } from 'notistack';
import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';

const StyledSnackbar = styled(({ fRef, id, message, variant, iconVariant, className, action = (id) => id }) => (
   <SnackbarContent ref={fRef} className={`${className} notistack-SnackbarContent-${variant}`}>
      <Typography component="div" className="notistack-SnackbarContent-message">
         {iconVariant[variant]}
         <Typography component="span">{message}</Typography>
      </Typography>
      {action(id)}
   </SnackbarContent>
))(({ theme }) => ({
   backgroundColor: theme.palette.background.default,
   fontFamily: theme.typography.fontFamily,
   fontWeight: 400,
   fontSize: '0.875rem',
   lineHeight: 1.43,
   letterSpacing: '0.01071em',
   color: theme.palette.common.white,
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   padding: '6px 16px',
   borderRadius: '4px',
   boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
   '&.notistack-SnackbarContent-default': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      ...theme.applyStyles("dark", {
         color: theme.palette.common.white
      })
   },
   '&.notistack-SnackbarContent-success': {
      backgroundColor: theme.palette.success.main,
   },
   '&.notistack-SnackbarContent-error': {
      backgroundColor: theme.palette.error.main,
   },
   '&.notistack-SnackbarContent-warning': {
      backgroundColor: theme.palette.warning.main,
   },
   '&.notistack-SnackbarContent-info': {
      backgroundColor: theme.palette.info.main,
   },
   '& > .notistack-SnackbarContent-message': {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 0',
   },
}));

const ThemedSnackbar = memo(
   forwardRef((props, forwardedRef) => {
      return <StyledSnackbar fRef={forwardedRef} {...props} />;
   }),
);

ThemedSnackbar.displayName = 'ThemedSnackbar';

ThemedSnackbar.propTypes = {
   id: PropTypes.string.isRequired,
   message: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
   variant: PropTypes.string.isRequired,
   action: PropTypes.func,
   iconVariant: PropTypes.shape({}),
};

export default ThemedSnackbar;
