import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Plugin } from 'ckeditor5';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

// Styled button using MUI's styled API
const SidebarToggleButton = styled(Button)(({ theme, isactive }) => ({
   backgroundColor: theme.palette.background.paper,
   color: theme.palette.text.primary,
   borderRadius: '50%', // Circular hover
   padding: theme.spacing(1),
   minWidth: 40,
   minHeight: 40,
   transition: 'background-color 0.3s ease',
   border: '2px dotted transparent',

   '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.primary.main, // Change border color on hover
   },

   '& svg': {
      fontSize: '24px',
      color: isactive ? theme.palette.primary.main : theme.palette.text.secondary,
      transition: 'color 0.3s ease',
   },
}));

// Functional Component to handle button and icon logic
function ToggleButton({ annotationsContainer, annotationsUIsPlugin, isCollapsed, setIsCollapsed }) {
   const handleClick = () => {
      const isCurrentlyCollapsed = annotationsContainer.classList.toggle('ck-hidden');
      setIsCollapsed(isCurrentlyCollapsed); // Update the collapsed state

      // Change UI based on the current state
      if (isCurrentlyCollapsed) {
         annotationsUIsPlugin.switchTo('inline');
      } else {
         annotationsUIsPlugin.switchTo('wideSidebar');
      }
   };

   return (
      <Tooltip title={isCollapsed ? 'Show annotations sidebar' : 'Hide annotations sidebar'} placement="right" arrow>
         <SidebarToggleButton
            onClick={handleClick}
            isactive={isCollapsed ? 1 : 0} // Toggle active state based on collapse
         >
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
         </SidebarToggleButton>
      </Tooltip>
   );
}

ToggleButton.propTypes = {
   annotationsContainer: PropTypes.object,
   annotationsUIsPlugin: PropTypes.object,
   isCollapsed: PropTypes.bool,
   setIsCollapsed: PropTypes.func,
};
export default class AnnotationsSidebarToggler extends Plugin {
   static get requires() {
      return ['AnnotationsUIs'];
   }

   static get pluginName() {
      return 'AnnotationsSidebarToggler';
   }

   init() {
      const annotationsUIsPlugin = this.editor.plugins.get('AnnotationsUIs');
      const annotationsContainer = this.editor.config.get('sidebar.container');
      const sidebarContainer = annotationsContainer.parentElement;

      let isCollapsed = true; // Start with sidebar collapsed

      const buttonContainer = document.createElement('div');

      const root = createRoot(buttonContainer);
      // Render the button into the container
      const renderButton = () => {
         root.render(
            <ToggleButton
               annotationsContainer={annotationsContainer}
               annotationsUIsPlugin={annotationsUIsPlugin}
               isCollapsed={isCollapsed}
               setIsCollapsed={(state) => {
                  isCollapsed = state; // Update the state
                  renderButton(); // Re-render the button to reflect the new state
               }}
            />
         );
      };

      renderButton(); // Initial render of the button

      // Insert the button into the sidebar container
      sidebarContainer.insertBefore(buttonContainer, annotationsContainer);
   }

   destroy() {
      const sidebarContainer = this.editor.config.get('sidebar.container').parentElement;
      const buttonContainer = sidebarContainer.querySelector('div');
      if (buttonContainer) {
         buttonContainer.remove();
      }

      return super.destroy();
   }
}
