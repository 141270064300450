import './utils/wydr';

import { enableAkitaProdMode } from '@datorama/akita';

const loadDevTools = async () => {
   const { akitaDevtools } = await import('@datorama/akita');
   akitaDevtools({
      sortAlphabetically: true,
   });
};

if (process.env.NODE_ENV === 'production') {
   enableAkitaProdMode();
} else {
   loadDevTools();
}

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far as farpro } from '@fortawesome/pro-regular-svg-icons';
import { fas as faspro } from '@fortawesome/pro-solid-svg-icons';
import { Loader } from '@googlemaps/js-api-loader';
import { LicenseInfo } from '@mui/x-license';
import * as Sentry from '@sentry/react';
import App from 'components/App/App';
import { UserContextComponent } from 'context/UserContext';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-grid-layout/css/styles.css';
import { HelmetProvider } from 'react-helmet-async';
import 'react-resizable/css/styles.css';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import ApplicationService from 'state/Application/service';
import 'styles/globals.scss';
import axiosClient from 'utils/axiosBaseClient';
import packageInfo from '../package.json';

const applicationService = new ApplicationService();

LicenseInfo.setLicenseKey('393e1ac5a8b3aed67085b7a03697384eTz04NTIzMyxFPTE3NDA3MjYzNjkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

axiosClient
   .get(`/auth/secrets`)
   .then(({ data }) => {
      const { googleMapsClientAPIKey: apiKey, 'recaptcha-siteKey': sitekey, NODE_ENV, CUSTOMER } = data;

      if (NODE_ENV) {
         applicationService.setProperty('NODE_ENV', NODE_ENV);
      }

      if (CUSTOMER) {
         applicationService.setProperty('CUSTOMER', CUSTOMER);
      }

      if (sitekey) {
         applicationService.setProperty('siteKey', sitekey);
         const script = document.createElement('script');
         script.type = 'text/javascript';
         script.async = true;
         script.src = `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`;
         document.head.appendChild(script);

         setTimeout(() => applicationService.setLoading(false), 2000);
      } else {
         applicationService.setLoading(false);
      }

      if (apiKey) {
         applicationService.setProperty('googleMapsAPIKey', apiKey);

         try {
            const googleMapsAPILoader = new Loader({
               apiKey,
               version: 'weekly',
               libraries: ['places'],
            });

            googleMapsAPILoader.importLibrary('places');
         } catch (error) {
            return applicationService.setError(error);
         }
      }

      return true;
   })
   .catch((error) => applicationService.setError(error));

if (!['test', 'local'].includes(process.env.NODE_ENV) && !process.env.REACT_APP_DOMAIN) {
   const environment =
      process.env.NODE_ENV === 'production' && /demo\.envoria\.app/i.test(window.location.hostname) ? 'development' : process.env.NODE_ENV;

   Sentry.init({
      dsn: 'https://e19b55a824f0bf56c478a7c0f5557c28@o4507479916347392.ingest.de.sentry.io/4507480733974608',
      enabled: environment !== 'development',
      release: packageInfo.version,
      integrations: [
         Sentry.browserTracingIntegration(),
         Sentry.replayIntegration(),
         Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
         }),
      ],
      tracePropagationTargets: [/^https:\/\/\w*\.demo\.envoria\.app/, /^https:\/\/\w*\.envoria\.app/, /^https:\/\/\w*\.haniel\.de/],
      // Performance Monitoring
      tracesSampleRate: environment === 'production' ? 0.1 : 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: environment === 'production' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: environment === 'production' ? 0.1 : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
   });
}

library.add(fas, far, faspro, farpro, fab);

const container = document.getElementById('app');

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
   <React.StrictMode>
      <HelmetProvider>
         <UserContextComponent>
            <App />
         </UserContextComponent>
      </HelmetProvider>
   </React.StrictMode>
);
