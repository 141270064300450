import { Password } from '@mui/icons-material';
import Icon from 'components/Icon';
import { ApprovalStatus, EntityRelationType, OrganisationType } from 'utils/enum';

export function getEntityKindIcon(kind: string) {
   let icon: string | undefined;

   switch (kind) {
      case 'all':
         icon = 'search';
         break;
      case 'emissionfactor':
         icon = 'calculator-simple';
         break;
      case 'kpiarea':
      case 'kpi':
      case 'kpisub':
      case 'kpicontent':
      case 'kpifield':
         icon = 'pen-field';
         break;
      case 'businessactivity':
         icon = 'briefcase';
         break;
      case 'taxonomyactivity':
         icon = 'leaf';
         break;
      case 'emissioncategory':
         icon = 'smoke';
         break;
      case 'organisation':
         icon = 'sitemap';
         break;
      case 'chart':
         icon = 'chart-column';
         break;
      case 'report':
         icon = 'book';
         break;
      case 'reportdocument':
         icon = 'file-word';
         break;
      case 'reportingstandard':
         icon = 'file-certificate';
         break;
      case 'task':
         icon = 'list-check';
         break;
      case 'user':
         icon = 'user';
         break;
      case 'group':
         icon = 'user-group';
         break;
      case 'currency':
         icon = 'coins';
         break;
      case 'footprint':
         icon = 'shoe-prints';
         break;
      case 'footprintcalculation':
      case 'fpcalculation':
         icon = 'calculator';
         break;
      case 'footprintcalculationfield':
      case 'fpcalculationfield':
         icon = 'shoe-prints';
         break;
      case 'refArea':
         icon = 'bullseye';
         break;
      case 'attachment':
      case 'taxonomyresponseattachment':
         icon = 'attachment';
         break;
      case 'taxonomycriteriaresponse':
         icon = 'leaf';
         break;
      case 'searchHistory':
         icon = 'history';
         break;
      default:
         break;
   }

   return <Icon icon={icon} fontSize="small" />;
}

export function getOrganisationIcon(organisationType: keyof typeof OrganisationType) {
   let icon: string | undefined;

   switch (organisationType) {
      case OrganisationType.REGION:
         icon = 'globe';
         break;
      case OrganisationType.COMMUNE:
         icon = 'users';
         break;
      case OrganisationType.AREA:
         icon = 'layer-group';
         break;
      case OrganisationType.PRODUCT:
         icon = 'tube-chemistry';
         break;
      case OrganisationType.PORTFOLIO:
         icon = 'folder-open';
         break;
      case OrganisationType.INVESTMENT:
         icon = 'hand-holding-usd';
         break;
      case OrganisationType.ORGANIZATION:
      default:
         icon = 'building';
         break;
   }

   return <Icon icon={icon} />;
}

export function getEntityRelationIcon(entityRelation: keyof typeof EntityRelationType) {
   let icon: string | undefined;

   switch (entityRelation) {
      case EntityRelationType.CUSTOMER:
         icon = 'truck-field';
         break;
      case EntityRelationType.SUPPLIER:
         icon = 'user-check';
         break;
      case EntityRelationType.SUBSIDIARY:
      default:
         icon = 'building';
         break;
   }

   return <Icon icon={icon} />;
}

export function getProviderIcon(provider: string) {
   switch (provider) {
      case 'GOOGLE':
         return <Icon icon="google" fontSize={40} />;
      case 'AZURE':
         return <Icon icon="microsoft" />;
      case 'SLACK':
         return <Icon icon="slack" />;
      case 'GITHUB':
         return <Icon icon="github" fontSize={20} />;
      case 'OKTA':
         return <Icon icon="okta" />;
      case 'WEBAUTHN':
         return <Icon icon="webauthn" />;
      case 'SAML':
         return <Icon icon="saml" />;
      case 'OPENID':
         return <Icon icon="openid" />;
      default:
         return <Password />;
   }
}

export function getAuditStatusIcon(auditStatus: string | keyof typeof ApprovalStatus): string {
   switch (auditStatus) {
      case ApprovalStatus.REVIEWED:
         return 'check_circle_outline';
      case ApprovalStatus.APPROVED:
         return 'check_circle';
      case ApprovalStatus.ARCHIVED:
         return 'inventory_2';
      case 'UNARCHIVE':
         return 'unarchive';
      case 'UNAPPROVE':
         return 'thumbs-down';
      case 'UNREVIEW':
         return 'thumbs-down';
      case 'REVIEW':
         return 'eye';
      case 'APPROVE':
         return 'thumbs-up';
      case 'ARCHIVE':
         return 'box-archive';
      case ApprovalStatus.NO_VERIFICATION_NEEDED:
      case ApprovalStatus.IN_PROGRESS:
      default:
         return 'hardware';
   }
}
