import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Plugin } from 'ckeditor5';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

// Styled button using MUI's
const SidebarToggleButton = styled(Button)(({ theme, isactive }) => ({
   backgroundColor: theme.palette.background.paper,
   color: theme.palette.text.primary,
   borderRadius: '50%', // Circular button
   padding: theme.spacing(1),
   minWidth: 40,
   minHeight: 40,
   transition: 'background-color 0.3s ease',
   border: '2px dotted transparent',

   '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.primary.main, // Change border color on hover
   },

   '& svg': {
      fontSize: '24px',
      color: isactive ? theme.palette.primary.main : theme.palette.text.secondary,
      transition: 'color 0.3s ease',
   },
}));

// Styled component for SVG icons
const StyledSVG = styled('svg')(({ theme }) => ({
   width: '24px',
   height: '24px',
   fill: theme.palette.text.secondary, // Default fill color
   transition: 'fill 0.3s ease',

   // Change fill color when active
   '&.active': {
      fill: theme.palette.primary.main,
   },
}));

// Functional Component for the button and icon logic
function ToggleButton({ documentOutlineContainer, isCollapsed, setIsCollapsed }) {
   const handleClick = () => {
      const isCurrentlyCollapsed = documentOutlineContainer.classList.toggle('ck-hidden');
      setIsCollapsed(isCurrentlyCollapsed); // Update the collapsed state
   };

   const DOCUMENT_OUTLINE_ICON = (
      <StyledSVG viewBox="0 0 20 20" className={isCollapsed ? '' : 'active'}>
         <path d="M5 9.5a.5.5 0 0 0 .5-.5v-.5A.5.5 0 0 0 5 8H3.5a.5.5 0 0 0-.5.5V9a.5.5 0 0 0 .5.5H5Z" />
         <path d="M5.5 12a.5.5 0 0 1-.5.5H3.5A.5.5 0 0 1 3 12v-.5a.5.5 0 0 1 .5-.5H5a.5.5 0 0 1 .5.5v.5Z" />
         <path d="M5 6.5a.5.5 0 0 0 .5-.5v-.5A.5.5 0 0 0 5 5H3.5a.5.5 0 0 0-.5.5V6a.5.5 0 0 0 .5.5H5Z" />
         <path
            clipRule="evenodd"
            d="M2 19a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2Zm6-1.5h10a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H8v15Zm-1.5-15H2a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h4.5v-15Z"
         />
      </StyledSVG>
   );

   const COLLAPSE_OUTLINE_ICON = (
      <StyledSVG viewBox="0 0 20 20" className={isCollapsed ? 'active' : ''}>
         <path d="M11.463 5.187a.888.888 0 1 1 1.254 1.255L9.16 10l3.557 3.557a.888.888 0 1 1-1.254 1.255L7.26 10.61a.888.888 0 0 1 .16-1.382l4.043-4.042z" />
      </StyledSVG>
   );

   return (
      (<Tooltip
         title={isCollapsed ? 'Show document outline' : 'Hide document outline'}
         placement="bottom"
         arrow
         slotProps={{
            popper: {
               modifiers: [
                  {
                     name: 'preventOverflow',
                     options: {
                        padding: 5, // Adjust padding to avoid overlap
                     },
                  },
                  {
                     name: 'offset',
                     options: {
                        offset: [0, 10], // Adjust to position the tooltip lower
                     },
                  },
               ],
            }
         }}
      >
         <SidebarToggleButton
            onClick={handleClick}
            isactive={isCollapsed ? 1 : 0} // Toggle active state based on collapse
         >
            {isCollapsed ? DOCUMENT_OUTLINE_ICON : COLLAPSE_OUTLINE_ICON}
         </SidebarToggleButton>
      </Tooltip>)
   );
}

ToggleButton.propTypes = {
   documentOutlineContainer: PropTypes.object.isRequired,
   isCollapsed: PropTypes.bool.isRequired,
   setIsCollapsed: PropTypes.func.isRequired,
};

export default class DocumentOutlineToggler extends Plugin {
   static get requires() {
      return ['DocumentOutline'];
   }

   static get pluginName() {
      return 'DocumentOutlineToggler';
   }

   init() {
      const documentOutlineContainer = this.editor.config.get('documentOutline.container');
      const sidebarContainer = documentOutlineContainer?.parentElement;

      let isCollapsed = true; // Start with sidebar collapsed

      const buttonContainer = document.createElement('div');

      const root = createRoot(buttonContainer);

      // Render the button into the container
      const renderButton = () => {
         root.render(
            <ToggleButton
               documentOutlineContainer={documentOutlineContainer}
               isCollapsed={isCollapsed}
               setIsCollapsed={(state) => {
                  isCollapsed = state; // Update the state
                  renderButton(); // Re-render the button to reflect the new state
               }}
            />
         );
      };

      renderButton(); // Initial render of the button

      // Insert the button into the sidebar container
      sidebarContainer.insertBefore(buttonContainer, documentOutlineContainer);
   }

   destroy() {
      const sidebarContainer = this.editor.config.get('documentOutline.container').parentElement;
      const buttonContainer = sidebarContainer.querySelector('div');
      if (buttonContainer) {
         buttonContainer.remove();
      }

      return super.destroy();
   }
}
