import {
   applyTransaction,
   arrayAdd,
   arrayRemove,
   arrayUpdate,
   arrayUpsert,
   combineQueries,
   filterNilValue,
   getEntityStoreByName,
   PaginatorPlugin,
} from '@datorama/akita';
import { activeOrganisationId$ } from 'context/OrganisationContext/query';
import { context$ as periodContext$ } from 'context/PeriodContext/query';
import { includeSubsidiaries$, locale$, pageSize$, userContextQuery } from 'context/UserContext/query';
import { formatISO, isValid, parseISO } from 'date-fns';
import { isEqual, isInteger, isObject, isPlainObject, omit, pick } from 'lodash-es';
import {
   BehaviorSubject,
   combineLatest,
   distinctUntilChanged,
   EMPTY,
   EmptyError,
   firstValueFrom,
   lastValueFrom,
   map,
   of,
   share,
   switchMap,
   tap,
} from 'rxjs';
import axiosClient from 'utils/axiosAPIClient';
import { ApprovalStatus } from 'utils/enum';
import { handleError } from 'utils/errorHandler';
import HistoryService from './History/service';

export default class CRUDService {
   // eslint-disable-next-line sonarjs/public-static-readonly
   static apiVersion = 'v2';

   constructor(
      entityName,
      store,
      query,
      collections = [],
      useOrganisationContext = false,
      usePeriodContext = false,
      useScope = undefined,
      trackChanges = false,
      includeSubsidiaries = undefined,
      paginate = false
   ) {
      this.isInitial = true;
      this.isLoading = false;
      this.entityName = entityName;
      this.store = store;
      this.query = query;
      this.version = CRUDService.apiVersion;
      this.collections = collections;
      this.useOrganisationContext = useOrganisationContext;
      this.usePeriodContext = usePeriodContext;
      this.useScope = useScope;
      this.includeSubsidiaries = includeSubsidiaries;
      this.paginate = paginate;
      this.httpClient = axiosClient;
      this.hasPageChangeObservable = false;
      this.userContextQuery = userContextQuery;
      this.historyService = new HistoryService();
      this.queryParams = undefined;
      this.allEntitiesAbortController = new AbortController();
      this.pendingGetAllEntitiesRequests = new Set();
      this.customQueryParams = undefined;

      this.queryParamsObservable = this.getQueryParams();

      this._setParams = this.getQueryParams().subscribe();

      if (this.paginate) {
         this.paginator = new PaginatorPlugin(this.query, {
            // cacheTimeout: interval(1200000),
            clearStoreWithCache: false,
         });
         this.pageChanges$ = new BehaviorSubject(EMPTY);
         this.pagination$ = this.pageChanges$.pipe(
            filterNilValue(),
            switchMap((source) => source)
         );
      }

      locale$.pipe(filterNilValue()).subscribe((locale) => {
         this.setRequestLocale(locale);
      });
   }

   appendDataToStore(data, store = this.store) {
      store.update((prevState) => ({
         ...prevState,
         ...data,
      }));
   }

   async reviewEntity(entityIdOrStandard, forceAudit, modelName = null) {
      const userContext = this.userContextQuery.getValue();
      const reviewerId = userContext?.id;
      const entityName = this.entityName;
      const includeSubsidiaries = userContext?.includeSubsidiaries;
      let organisationId;
      if (this.useOrganisationContext || entityName === 'kpiscopes') {
         organisationId = userContext?.activeOrganisation?.id;
      }

      let from, to;
      if (userContext?.activePeriod) {
         from = userContext?.activePeriod?.from;
         to = userContext?.activePeriod?.to;
      }

      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const params = new URLSearchParams(this.queryParams);
      params.set('force', forceAudit);
      if (includeSubsidiaries !== undefined) {
         params.set('includeSubsidiaries', includeSubsidiaries);
      }

      let corePath;
      if (isInteger(Number.parseInt(entityIdOrStandard, 10)) && !modelName) {
         corePath = `${entityName}/${entityIdOrStandard}`;
      } else if (entityName === 'kpiscopes') {
         corePath = `scoping/${entityIdOrStandard}`;
      } else if (entityName === 'taxonomy/minimumsafeguards') {
         corePath = entityName;
      } else if (modelName) {
         corePath = `${modelName}/${entityIdOrStandard}`;
      }

      return this.httpClient
         .post(`/${this.version}/${corePath}/review?${params.toString() ?? ''}`)
         .then((resp) => {
            if (entityName === 'kpifields') {
               this.refreshCurrentKPIProgress();
               return this.#setStatus(ApprovalStatus.REVIEWED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['taxonomy/businessactivities', 'taxonomy/minimumsafeguards'].includes(entityName) && !modelName) {
               return this.#setStatus(ApprovalStatus.REVIEWED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['kpiValueItem', 'kpivalueitem'].includes(modelName)) {
               this.#setStatus(ApprovalStatus.REVIEWED, entityIdOrStandard, organisationId, from, to, modelName, resp.data.auditValues);
               return [];
            } else if (['kpisubs', 'kpivalues'].includes(entityName)) {
               return this.#setStatus(ApprovalStatus.REVIEWED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (entityName === 'kpiscopes') {
               return this.kpiScopesStore.update(
                  ({ materialityType, from: scopeFrom, to: scopeTo, organisationId: scopeOrganisationId }) =>
                     scopeFrom === from && scopeTo === to && scopeOrganisationId === organisationId && !!materialityType,
                  {
                     status: ApprovalStatus.REVIEWED,
                     reviewerId,
                  }
               );
            } else {
               const user = pick(this.userContextQuery.getValue(), [
                  'id',
                  'salutation',
                  'image',
                  'userName',
                  'firstName',
                  'lastName',
                  'email',
                  'lang',
                  'locale',
               ]);

               return this.store.update(entityIdOrStandard, {
                  status: ApprovalStatus.REVIEWED,
                  reviewerId,
                  reviewer: user,
                  ...resp.data,
               });
            }
         })
         .catch((error) => {
            if (error?.response?.status === 500) {
               this.setError(error);
            }
            return error.response;
         });
   }

   async unreviewEntity(entityIdOrStandard, forceAudit, modelName = null) {
      const userContext = this.userContextQuery.getValue();
      let organisationId;
      const entityName = this.entityName;
      const includeSubsidiaries = userContext?.includeSubsidiaries;

      if (this.useOrganisationContext || entityName === 'kpiscopes') {
         organisationId = userContext?.activeOrganisation?.id;
      }

      let from, to;
      if (userContext?.activePeriod) {
         from = userContext?.activePeriod?.from;
         to = userContext?.activePeriod?.to;
      }

      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const params = new URLSearchParams(this.queryParams);
      params.set('force', forceAudit);
      if (includeSubsidiaries !== undefined) {
         params.set('includeSubsidiaries', includeSubsidiaries);
      }

      let corePath;
      if (isInteger(Number.parseInt(entityIdOrStandard, 10)) && !modelName) {
         corePath = `${entityName}/${entityIdOrStandard}`;
      } else if (entityName === 'kpiscopes') {
         corePath = `scoping/${entityIdOrStandard}`;
      } else if (entityName === 'taxonomy/minimumsafeguards') {
         corePath = 'taxonomy/minimumsafeguards';
      } else if (modelName) {
         corePath = `${modelName}/${entityIdOrStandard}`;
      }

      return this.httpClient
         .post(`/${this.version}/${corePath}/unreview?${params.toString() ?? ''}`)
         .then((resp) => {
            if (entityName === 'kpifields') {
               this.refreshCurrentKPIProgress();
               return this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['taxonomy/businessactivities', 'taxonomy/minimumsafeguards'].includes(entityName) && !modelName) {
               return this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['kpiValueItem', 'kpivalueitem'].includes(modelName)) {
               this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, modelName, resp.data.auditValues);
               return [];
            } else if (['kpisubs', 'kpivalues'].includes(entityName)) {
               return this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (entityName === 'kpiscopes') {
               return this.kpiScopesStore.update(
                  ({ materialityType, from: scopeFrom, to: scopeTo, organisationId: scopeOrganisationId }) =>
                     scopeFrom === from && scopeTo === to && scopeOrganisationId === organisationId && !!materialityType,
                  {
                     status: ApprovalStatus.IN_PROGRESS,
                     reviewerId: null,
                     reviwer: null,
                  }
               );
            } else {
               return this.store.update(entityIdOrStandard, {
                  status: ApprovalStatus.IN_PROGRESS,
                  reviewerId: null,
                  reviewer: null,
                  ...resp.data,
               });
            }
         })
         .catch((error) => {
            if (error?.response?.status === 500) {
               this.setError(error);
            }
            return error.response;
         });
   }

   async approveEntity(entityIdOrStandard, forceAudit, modelName = null) {
      const userContext = this.userContextQuery.getValue();
      const approverId = userContext?.id;
      const entityName = this.entityName;
      const includeSubsidiaries = userContext?.includeSubsidiaries;

      let organisationId;
      if (this.useOrganisationContext || entityName === 'kpiscopes') {
         organisationId = userContext?.activeOrganisation?.id;
      }

      let from, to;
      if (userContext?.activePeriod) {
         from = userContext?.activePeriod?.from;
         to = userContext?.activePeriod?.to;
      }

      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const params = new URLSearchParams(this.queryParams);
      params.set('force', forceAudit);
      if (includeSubsidiaries !== undefined) {
         params.set('includeSubsidiaries', includeSubsidiaries);
      }

      let corePath;
      if (isInteger(Number.parseInt(entityIdOrStandard, 10)) && !modelName) {
         corePath = `${entityName}/${entityIdOrStandard}`;
      } else if (entityName === 'kpiscopes') {
         corePath = `scoping/${entityIdOrStandard}`;
      } else if (entityName === 'taxonomy/minimumsafeguards') {
         corePath = 'taxonomy/minimumsafeguards';
      } else if (modelName) {
         corePath = `${modelName}/${entityIdOrStandard}`;
      } else if (isValid(entityIdOrStandard) && entityName === 'currenciesrates') {
         const currencyTo = userContext?.activeOrganisation?.currency?.shortCode;
         params.set('currencyTo', currencyTo);
         corePath = `${entityName}/${formatISO(entityIdOrStandard, { representation: 'date' })}`;
      }

      return this.httpClient
         .post(`/${this.version}/${corePath}/approve?${params.toString() ?? ''}`)
         .then((resp) => {
            if (entityName === 'kpifields') {
               this.refreshCurrentKPIProgress();
               return this.#setStatus(ApprovalStatus.APPROVED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['taxonomy/businessactivities', 'taxonomy/minimumsafeguards'].includes(entityName) && !modelName) {
               return this.#setStatus(ApprovalStatus.APPROVED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['kpiValueItem', 'kpivalueitem'].includes(modelName)) {
               this.#setStatus(ApprovalStatus.APPROVED, entityIdOrStandard, organisationId, from, to, modelName, resp.data.auditValues);
               return [];
            } else if (['kpisubs', 'kpivalues', 'currenciesrates'].includes(entityName)) {
               return this.#setStatus(ApprovalStatus.APPROVED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (entityName === 'kpiscopes') {
               return this.kpiScopesStore.update(
                  ({ materialityType, from: scopeFrom, to: scopeTo, organisationId: scopeOrganisationId }) =>
                     scopeFrom === from && scopeTo === to && scopeOrganisationId === organisationId && !!materialityType,
                  {
                     status: ApprovalStatus.APPROVED,
                     approverId,
                  }
               );
            } else {
               const user = pick(this.userContextQuery.getValue(), [
                  'id',
                  'salutation',
                  'image',
                  'userName',
                  'firstName',
                  'lastName',
                  'email',
                  'lang',
                  'locale',
               ]);

               return this.store.update(entityIdOrStandard, {
                  status: ApprovalStatus.APPROVED,
                  approverId,
                  approver: user,
                  ...resp.data,
               });
            }
         })
         .catch((error) => {
            if (error?.response?.status === 500) {
               this.setError(error);
            }
            return error.response;
         });
   }

   async unapproveEntity(entityIdOrStandard, forceAudit, modelName = null) {
      const userContext = this.userContextQuery.getValue();
      let organisationId;
      const entityName = this.entityName;
      const includeSubsidiaries = userContext?.includeSubsidiaries;

      if (this.useOrganisationContext || entityName === 'kpiscopes') {
         organisationId = userContext?.activeOrganisation?.id;
      }

      let from, to;
      if (userContext?.activePeriod) {
         from = userContext?.activePeriod?.from;
         to = userContext?.activePeriod?.to;
      }

      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const params = new URLSearchParams(this.queryParams);
      params.set('force', forceAudit);
      if (includeSubsidiaries !== undefined) {
         params.set('includeSubsidiaries', includeSubsidiaries);
      }

      let corePath;
      if (isInteger(Number.parseInt(entityIdOrStandard, 10)) && !modelName) {
         corePath = `${entityName}/${entityIdOrStandard}`;
      } else if (entityName === 'kpiscopes') {
         corePath = `scoping/${entityIdOrStandard}`;
      } else if (entityName === 'taxonomy/minimumsafeguards') {
         corePath = 'taxonomy/minimumsafeguards';
      } else if (modelName) {
         corePath = `${modelName}/${entityIdOrStandard}`;
      } else if (isValid(entityIdOrStandard) && entityName === 'currenciesrates') {
         const currencyTo = userContext?.activeOrganisation?.currency?.shortCode;
         params.set('currencyTo', currencyTo);
         corePath = `${entityName}/${formatISO(entityIdOrStandard, { representation: 'date' })}`;
      }

      return this.httpClient
         .post(`/${this.version}/${corePath}/unapprove?${params.toString() ?? ''}`)
         .then((resp) => {
            if (entityName === 'kpifields') {
               this.refreshCurrentKPIProgress();
               return this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['taxonomy/businessactivities', 'taxonomy/minimumsafeguards'].includes(entityName) && !modelName) {
               return this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['kpiValueItem', 'kpivalueitem'].includes(modelName)) {
               this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, modelName, resp.data.auditValues);
               return [];
            } else if (['kpisubs', 'kpivalues'].includes(entityName)) {
               return this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if ('currenciesrates' === entityName) {
               return this.#setStatus(ApprovalStatus.REVIEWED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (entityName === 'kpiscopes') {
               return this.kpiScopesStore.update(
                  ({ materialityType, from: scopeFrom, to: scopeTo, organisationId: scopeOrganisationId }) =>
                     scopeFrom === from && scopeTo === to && scopeOrganisationId === organisationId && !!materialityType,
                  {
                     status: ApprovalStatus.IN_PROGRESS,
                     approverId: null,
                     approver: null,
                     reviewerId: null,
                     reviewer: null,
                  }
               );
            } else {
               return this.store.update(entityIdOrStandard, {
                  status: ApprovalStatus.IN_PROGRESS,
                  approverId: null,
                  approver: null,
                  reviewerId: null,
                  reviewer: null,
                  ...resp.data,
               });
            }
         })
         .catch((error) => {
            if (error?.response?.status === 500) {
               this.setError(error);
            }
            return error.response;
         });
   }

   async rejectEntity(entityIdOrStandard, forceAudit, justification, modelName = null) {
      const userContext = this.userContextQuery.getValue();
      let organisationId;
      const entityName = this.entityName;
      const includeSubsidiaries = userContext?.includeSubsidiaries;

      if (this.useOrganisationContext || entityName === 'kpiscopes') {
         organisationId = userContext?.activeOrganisation?.id;
      }

      let from, to;
      if (userContext?.activePeriod) {
         from = userContext?.activePeriod?.from;
         to = userContext?.activePeriod?.to;
      }

      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const params = new URLSearchParams(this.queryParams);
      params.set('force', forceAudit);
      if (includeSubsidiaries !== undefined) {
         params.set('includeSubsidiaries', includeSubsidiaries);
      }

      let corePath;
      if (isInteger(Number.parseInt(entityIdOrStandard, 10)) && !modelName) {
         corePath = `${entityName}/${entityIdOrStandard}`;
      } else if (entityName === 'kpiscopes') {
         corePath = `scoping/${entityIdOrStandard}`;
      } else if (entityName === 'taxonomy/minimumsafeguards') {
         corePath = 'taxonomy/minimumsafeguards';
      } else if (modelName) {
         corePath = `${modelName}/${entityIdOrStandard}`;
      }

      return this.httpClient
         .post(`/${this.version}/${corePath}/reject?${params.toString() ?? ''}`, { justification })
         .then((resp) => {
            if (entityName === 'kpifields') {
               this.refreshCurrentKPIProgress();
               return this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['taxonomy/businessactivities', 'taxonomy/minimumsafeguards'].includes(entityName) && !modelName) {
               return this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['kpiValueItem', 'kpivalueitem'].includes(modelName)) {
               this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, modelName, resp.data.auditValues);
               return [];
            } else if (['kpisubs', 'kpivalues'].includes(entityName)) {
               return this.#setStatus(ApprovalStatus.IN_PROGRESS, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (entityName === 'kpiscopes') {
               return this.kpiScopesStore.update(
                  ({ materialityType, from: scopeFrom, to: scopeTo, organisationId: scopeOrganisationId }) =>
                     scopeFrom === from && scopeTo === to && scopeOrganisationId === organisationId && !!materialityType,
                  {
                     status: ApprovalStatus.IN_PROGRESS,
                     approverId: null,
                     reviewerId: null,
                  }
               );
            } else {
               return this.store.update(entityIdOrStandard, {
                  status: ApprovalStatus.IN_PROGRESS,
                  approverId: null,
                  approver: null,
                  reviewerId: null,
                  reviewer: null,
                  ...resp.data,
               });
            }
         })
         .catch((error) => {
            if (error?.response?.status === 500) {
               this.setError(error);
            }
            return error.response;
         });
   }

   async archiveEntity(entityIdOrStandard, forceAudit, modelName = null) {
      const userContext = this.userContextQuery.getValue();
      let organisationId;
      const entityName = this.entityName;
      const includeSubsidiaries = userContext?.includeSubsidiaries;

      if (this.useOrganisationContext || entityName === 'kpiscopes') {
         organisationId = userContext?.activeOrganisation?.id;
      }

      let from, to;
      if (userContext?.activePeriod) {
         from = userContext?.activePeriod?.from;
         to = userContext?.activePeriod?.to;
      }

      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const params = new URLSearchParams(this.queryParams);
      params.set('force', forceAudit);
      if (includeSubsidiaries !== undefined) {
         params.set('includeSubsidiaries', includeSubsidiaries);
      }

      let corePath;
      if (isInteger(Number.parseInt(entityIdOrStandard, 10)) && !modelName) {
         corePath = `${entityName}/${entityIdOrStandard}`;
      } else if (entityName === 'kpiscopes') {
         corePath = `scoping/${entityIdOrStandard}`;
      } else if (entityName === 'taxonomy/minimumsafeguards') {
         corePath = 'taxonomy/minimumsafeguards';
      } else if (modelName) {
         corePath = `${modelName}/${entityIdOrStandard}`;
      }

      return this.httpClient
         .post(`/${this.version}/${corePath}/archive?${params.toString() ?? ''}`)
         .then((resp) => {
            if (entityName === 'kpifields') {
               this.refreshCurrentKPIProgress();
               return this.#setStatus(ApprovalStatus.ARCHIVED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['taxonomy/businessactivities', 'taxonomy/minimumsafeguards'].includes(entityName) && !modelName) {
               return this.#setStatus(ApprovalStatus.ARCHIVED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['kpiValueItem', 'kpivalueitem'].includes(modelName)) {
               this.#setStatus(ApprovalStatus.ARCHIVED, entityIdOrStandard, organisationId, from, to, modelName, resp.data.auditValues);
               return [];
            } else if (['kpisubs', 'kpivalues'].includes(entityName)) {
               return this.#setStatus(ApprovalStatus.ARCHIVED, entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (entityName === 'kpiscopes') {
               return this.kpiScopesStore.update(
                  ({ materialityType, from: scopeFrom, to: scopeTo, organisationId: scopeOrganisationId }) =>
                     scopeFrom === from && scopeTo === to && scopeOrganisationId === organisationId && !!materialityType,
                  {
                     status: ApprovalStatus.ARCHIVED,
                  }
               );
            } else {
               return this.store.update(entityIdOrStandard, { status: ApprovalStatus.ARCHIVED, ...resp.data });
            }
         })
         .catch((error) => {
            if (error?.response?.status === 500) {
               this.setError(error);
            }
            return error.response;
         });
   }

   async unarchiveEntity(entityIdOrStandard, forceAudit, modelName = null) {
      const userContext = this.userContextQuery.getValue();
      let organisationId;
      const entityName = this.entityName;
      const includeSubsidiaries = userContext?.includeSubsidiaries;

      if (this.useOrganisationContext || entityName === 'kpiscopes') {
         organisationId = userContext?.activeOrganisation?.id;
      }

      let from, to;
      if (userContext?.activePeriod) {
         from = userContext?.activePeriod?.from;
         to = userContext?.activePeriod?.to;
      }

      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }
      const params = new URLSearchParams(this.queryParams);
      params.set('force', forceAudit);
      if (includeSubsidiaries !== undefined) {
         params.set('includeSubsidiaries', includeSubsidiaries);
      }

      let corePath;
      if (isInteger(Number.parseInt(entityIdOrStandard, 10)) && !modelName) {
         corePath = `${entityName}/${entityIdOrStandard}`;
      } else if (entityName === 'kpiscopes') {
         corePath = `scoping/${entityIdOrStandard}`;
      } else if (entityName === 'taxonomy/minimumsafeguards') {
         corePath = 'taxonomy/minimumsafeguards';
      } else if (modelName) {
         corePath = `${modelName}/${entityIdOrStandard}`;
      }

      return this.httpClient
         .post(`/${this.version}/${corePath}/unarchive?${params.toString() ?? ''}`)
         .then((resp) => {
            if (entityName === 'kpifields') {
               this.refreshCurrentKPIProgress();
               return this.#setStatus('UNARCHIVE', entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['taxonomy/businessactivities', 'taxonomy/minimumsafeguards'].includes(entityName) && !modelName) {
               return this.#setStatus('UNARCHIVE', entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (['kpiValueItem', 'kpivalueitem'].includes(modelName)) {
               this.#setStatus('UNARCHIVE', entityIdOrStandard, organisationId, from, to, modelName, resp.data.auditValues);
               return [];
            } else if (['kpisubs', 'kpivalues'].includes(entityName)) {
               return this.#setStatus('UNARCHIVE', entityIdOrStandard, organisationId, from, to, entityName, resp.data.auditValues);
            } else if (entityName === 'kpiscopes') {
               return this.kpiScopesStore.update(
                  ({ materialityType, from: scopeFrom, to: scopeTo, organisationId: scopeOrganisationId }) =>
                     scopeFrom === from && scopeTo === to && scopeOrganisationId === organisationId && !!materialityType,
                  {
                     status: ApprovalStatus.APPROVED,
                  }
               );
            } else {
               return this.store.update(entityIdOrStandard, { status: ApprovalStatus.APPROVED, ...resp.data });
            }
         })
         .catch((error) => {
            if (error?.response?.status === 500) {
               this.setError(error);
            }
            return error.response;
         });
   }

   #setStatus(status, entityId, organisationId, from, to, entityName, auditValues = []) {
      const user = pick(this.userContextQuery.getValue(), [
         'id',
         'salutation',
         'image',
         'userName',
         'firstName',
         'lastName',
         'email',
         'lang',
         'locale',
      ]);

      const setStatusValues = { status };

      switch (status) {
         case ApprovalStatus.IN_PROGRESS:
            setStatusValues.reviewer = null;
            setStatusValues.approver = null;
            break;
         case ApprovalStatus.REVIEWED:
            if (entityName !== 'currenciesrates') {
               setStatusValues.reviewer = user;
            }
            setStatusValues.approver = null;
            break;
         case ApprovalStatus.APPROVED:
            setStatusValues.approver = user;
            break;
         case 'UNARCHIVE':
            setStatusValues.status = ApprovalStatus.APPROVED;
            break;
         default:
            break;
      }

      if (entityName === 'kpifields') {
         const collectionName = 'rows';

         this.setStatusApprovalCenter(setStatusValues, auditValues);
         this.store.update((entity) => auditValues.includes(entity.id), { ...setStatusValues });
         this.store.update(
            (entity) => auditValues.includes(entity.id),
            ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: collectionName_.map((row) => ({
                  ...row,
                  ...setStatusValues,
                  values: (row?.values ?? []).map((value) => ({
                     ...value,
                     ...setStatusValues,
                  })),
               })),
            })
         );
         this.kpiValuesStore.update(
            (entity) =>
               auditValues.includes(entity.kpiFieldId) &&
               entity.organisationId === organisationId &&
               parseISO(entity.from) >= from &&
               (parseISO(entity.to) <= to || !entity.to),
            { ...setStatusValues }
         );
      } else if (entityName === 'kpisubs') {
         this.setStatusApprovalCenter(setStatusValues, auditValues);
         this.kpiFieldsStore.update((entity) => entity.kpiContent?.kpiSub?.id === entityId && auditValues.includes(entity.id), {
            ...setStatusValues,
         });
      } else if (entityName === 'taxonomy/businessactivities') {
         this.setStatusApprovalCenter(setStatusValues, [entityId]);
         this.store.update(entityId, ({ taxonomyActivityAssignments, organisationAssignments }) => ({
            taxonomyActivityAssignments: Array.isArray(taxonomyActivityAssignments)
               ? arrayUpdate(
                    taxonomyActivityAssignments,
                    (assignment) => assignment.validFrom === from && assignment.validTo === to && assignment.organisation.id === organisationId,
                    { ...setStatusValues }
                 )
               : [],
            organisationAssignments: Array.isArray(organisationAssignments)
               ? arrayUpdate(
                    organisationAssignments,
                    (assignment) => assignment.validFrom === from && assignment.validTo === to && assignment.organisation.id === organisationId,
                    { ...setStatusValues }
                 )
               : [],
         }));
      } else if (entityName === 'taxonomy/minimumsafeguards') {
         this.setStatus(organisationId, from, to, setStatusValues, auditValues);
      } else if (entityName === 'kpivalues') {
         const auditValue = auditValues[0];
         this.setStatusApprovalCenter(setStatusValues, [auditValue.id], 'kpivalue');
         this.kpiFieldsStore.update((entity) => auditValue?.kpiFieldId === entity.id, {
            ...setStatusValues,
         });
         this.store.update((entity) => auditValue?.id === entity.id, { ...setStatusValues });
      } else if (entityName === 'kpivalueitem') {
         this.setStatusApprovalCenter(setStatusValues, [entityId], 'kpivalueitem');
      } else if (entityName === 'currenciesrates') {
         this.setStatusApprovalCenter(setStatusValues, auditValues, 'currencyrate');
         this.store.update((entity) => auditValues.includes(entity.id), { ...setStatusValues });
      }
   }

   getPageChangesObservable() {
      return this.paginator.pageChanges.pipe(filterNilValue(), distinctUntilChanged());
   }

   getPageChangesCombinedObservable() {
      return combineLatest([this.getPageChangesObservable(), this.getPageParams(), this.getQueryParams()]).pipe(
         distinctUntilChanged(isEqual),
         switchMap((val) => {
            let page;
            let pageQueryParams;
            let generalQueryParams;

            if (Array.isArray(val)) {
               [page, pageQueryParams, generalQueryParams] = val;
            } else {
               page = this?.paginator?.currentPage ?? 1;
               pageQueryParams = this.pageQueryParams;
               generalQueryParams = this.queryParams;
            }

            const pageParams = new URLSearchParams(pageQueryParams);
            const queryParams = new URLSearchParams(generalQueryParams);

            const resetPage =
               Object.entries(Object.fromEntries(pageParams)).some(
                  ([key, value]) => !this.paginator.metadata.has(key) || this.paginator.metadata.get(key) !== value
               ) || this?.paginator?.metadata?.size === 0;

            const thePage = resetPage ? 1 : page;

            if (resetPage && this.paginator) {
               this.paginator.clearPage(1);
            }

            pageParams.forEach((value, key) => this.paginator.metadata.set(key, value));

            // TODO: understand the reason for this condition and why data has to be set to 'this.paginator.getQuery().getAll()'
            // Explanation: when we PATCH an entity, we always received the updated entity from the response. I think it does not make sense to
            // load the page again. For these cases, this.paginator.getQuery().getAll() is a hack to tell the paginator "this is the new data"
            // because otherwise I found it to be empty (if I don't provide that) or it will call the page again, which costs resources
            if (this.isLoading || this.query.hasActive()) {
               return this.paginator.getPage(() => Promise.resolve({ ...this.paginator.pagination, data: this.paginator.getQuery().getAll() }));
            }
            return this.paginator.getPage(() => this.getPage(thePage, pageParams, queryParams));
         }),
         share()
      );
   }

   getPageParams() {
      const dependencies = [];

      dependencies.push(pageSize$);

      let urlParams;

      if (dependencies.length) {
         return combineQueries(dependencies).pipe(
            distinctUntilChanged(isEqual),
            map((values) => {
               urlParams = new URLSearchParams();

               if (values.length === dependencies.length) {
                  if (values[0] !== 0) {
                     urlParams.set('limit', values[0]);
                  }

                  return `?${urlParams.toString()}`;
               }

               return false;
            }),
            distinctUntilChanged(),
            tap((pageQueryParams) => {
               if (this.pageQueryParams && this.pageQueryParams !== pageQueryParams && this.paginate && this?.paginator?.pages?.size > 0) {
                  this.paginator.clearCache();
                  this.paginator.clearCache();
               }

               this.pageQueryParams = pageQueryParams;
            })
         );
      }

      urlParams = new URLSearchParams();
      this.pageQueryParams = `?${urlParams.toString()}`;
      return of(`?${urlParams.toString()}`);
   }

   getQueryParams() {
      const dependencies = [];

      if (this.useOrganisationContext) {
         dependencies.push(activeOrganisationId$.pipe(filterNilValue()));
      }
      if (this.usePeriodContext) {
         dependencies.push(periodContext$.pipe(filterNilValue()));
      }
      if (this.includeSubsidiaries) {
         dependencies.push(includeSubsidiaries$.pipe(filterNilValue()));
      }

      let urlParams;

      if (dependencies.length) {
         return combineLatest(dependencies).pipe(
            distinctUntilChanged(isEqual),
            map((values) => {
               urlParams = new URLSearchParams(this.customQueryParams);

               if (this.useScope === true) {
                  urlParams.set('scope', true);
               } else if (this.useScope === false) {
                  urlParams.set('scope', false);
               } else {
                  urlParams.set('scope', 'any');
               }

               if (values.length === dependencies.length) {
                  const period = values.find((value) => value && Object.hasOwn(value, 'from') && Object.hasOwn(value, 'to'));
                  const includeSubsidiaries = values.find((value) => typeof value === 'boolean');
                  const organisationId = values.find((value) => typeof value === 'number');

                  if (organisationId) {
                     urlParams.set('organisationId', organisationId);
                  }
                  if (period) {
                     urlParams.set('from', period.from);
                     urlParams.set('to', period.to);
                  }
                  if (includeSubsidiaries !== undefined) {
                     urlParams.set('includeSubsidiaries', includeSubsidiaries);
                  }

                  if (
                     ((this.useOrganisationContext && organisationId) || !this.useOrganisationContext) &&
                     ((this.usePeriodContext && period) || !this.usePeriodContext) &&
                     ((this.includeSubsidiaries && includeSubsidiaries !== undefined) || !this.includeSubsidiaries)
                  ) {
                     return `?${urlParams.toString()}`;
                  }
               }

               return false;
            }),
            distinctUntilChanged(),
            tap((queryParams) => {
               if (this.queryParams && this.queryParams !== queryParams && this.paginate && this?.paginator?.pages?.size > 0) {
                  this.paginator.clearCache({ clearStore: true });
                  this.paginator.clearCache({ clearStore: true });
               }

               this.queryParams = queryParams;
            })
         );
      }

      urlParams = new URLSearchParams();

      if (this.useScope === true) {
         urlParams.set('scope', true);
      } else if (this.useScope === false) {
         urlParams.set('scope', false);
      } else {
         urlParams.set('scope', 'any');
      }

      this.queryParams = `?${urlParams.toString()}`;
      return of(`?${urlParams.toString()}`);
   }

   setError(error, store = this.store) {
      handleError(error, store);
   }

   getLoading() {
      return this.query.getValue().loading;
   }

   getError() {
      return this.query.getValue().error;
   }

   async getPage(page, pageQueryParams, generalQueryParams, entityName = this.entityName) {
      if (page && pageQueryParams && generalQueryParams) {
         const queryParams = new URLSearchParams(generalQueryParams);

         const additionalParams = new URLSearchParams(pageQueryParams);

         additionalParams.forEach((value, key) => queryParams.set(key, value));

         queryParams.set('page', page);

         return this.httpClient
            .get(`/${this.version}/${entityName}?${queryParams.toString()}`)
            .then((resp) => resp.data)
            .catch((error) => this.setError(error));
      }
      return undefined;
   }

   async getEntities(force = false, store = this.store, url = null) {
      if (this.paginate) {
         try {
            if (this.hasPageChangeObservable) {
               if (this?.paginator?.currentPage && this.paginator.hasPage(this.paginator.currentPage) && !force) {
                  return this.paginator.refreshCurrentPage();
               }

               return this.pageChanges$.next(this.getPageChangesCombinedObservable());
            }
            if (this.pageChanges$ && !this.pageChanges$.closed) {
               const innerObservable$ = this.pageChanges$.value;
               await lastValueFrom(innerObservable$);
            } else {
               this.pageChanges$ = new BehaviorSubject(EMPTY);
               this.pagination$ = this.pageChanges$.pipe(
                  filterNilValue(),
                  switchMap((source) => source)
               );
            }
         } catch (error) {
            if (error instanceof EmptyError) {
               if (!this.hasPageChangeObservable) {
                  this.hasPageChangeObservable = true;
                  return this.pageChanges$.next(this.getPageChangesCombinedObservable());
               }
               return undefined;
            }
            throw new Error(error);
         }
      }

      if (force && this.getEntitiesObservable && !this.getEntitiesObservable.closed) {
         this.getEntitiesObservable.unsubscribe();
         this.getEntitiesObservable = undefined;
      }

      if (this.getEntitiesObservable === undefined || this.getEntitiesObservable?.closed) {
         this.getEntitiesObservable = this.queryParamsObservable
            .pipe(
               tap((queryString) => {
                  if (typeof queryString === 'string') {
                     store.setLoading(true);

                     applyTransaction(() => {
                        if (this.query.hasActive()) {
                           this.loadCollections(this.query.getActiveId());
                        }

                        if (this.pendingGetAllEntitiesRequests.has(queryString)) {
                           return;
                        }

                        if (this.pendingGetAllEntitiesRequests.size > 0 && !this.pendingGetAllEntitiesRequests.has(queryString)) {
                           this.allEntitiesAbortController.abort(`${store.storeName}: ${queryString}`);
                           this.pendingGetAllEntitiesRequests.clear();
                           this.allEntitiesAbortController = new AbortController();
                        }

                        this.pendingGetAllEntitiesRequests.add(queryString);
                        const requestURL = url ?? `/${this.entityName}`;

                        this.httpClient
                           .get(`/${this.version}${requestURL}${queryString}`, {
                              signal: this.allEntitiesAbortController.signal,
                           })
                           .then((resp) => {
                              this.pendingGetAllEntitiesRequests.delete(queryString);
                              // TODO https://github.com/fisa-io/envoria-issues/issues/952
                              const items = Array.isArray(resp?.data?.items) || Array.isArray(resp?.data) ? (resp.data.items ?? resp.data) : null;

                              if (items) {
                                 if (this.query.hasActive() && items.some((item) => item[store.idKey] === this.query.getActiveId())) {
                                    store.updateActive(items.find((item) => item[store.idKey] === this.query.getActiveId()));
                                    const entity = this.query.getActive();
                                    const result = [entity, ...items.filter((item) => item[store.idKey] !== this.query.getActiveId())];
                                    store.set(result);
                                 } else {
                                    store.set(items);
                                 }
                              } else {
                                 store.set(resp.data);
                              }

                              return null;
                           })
                           .finally(() => {
                              store.setLoading(false);
                           })
                           .catch((error) => {
                              this.pendingGetAllEntitiesRequests.delete(queryString);

                              this.setError(error, store);
                           });
                     });
                  }

                  return null;
               })
            )
            .subscribe();
      }

      return null;
   }

   async refreshEntities() {
      return this.getEntities(true);
   }

   async sortEntities(sortedEntityIds, parentEntityName, entityId, collectionName) {
      if (sortedEntityIds.length === 0) {
         return Promise.resolve();
      }

      const path =
         parentEntityName && entityId && collectionName
            ? `/${this.version}/${parentEntityName}/${entityId}/${collectionName}/sort`
            : `/${this.version}/${this.entityName}/sort`;

      return this.httpClient
         .post(path, sortedEntityIds)
         .then((resp) => applyTransaction(() => resp.data.forEach(({ id, position }) => this.store.update(id, { position }))));
   }

   async loadEntity(entityUniqueIdentifier) {
      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }

      return this.httpClient
         .get(`/${this.version}/${this.entityName}/${entityUniqueIdentifier}${this.queryParams ?? ''}`)
         .then((resp) => {
            this.store.upsert(resp?.data?.id ?? entityUniqueIdentifier, resp.data);
            return resp?.data?.id;
         })
         .catch((error) => {
            this.setError(error);

            return false;
         });
   }

   getEntityState(entityId) {
      return this.query.getEntity(entityId);
   }

   hasEntity(entityId) {
      return this.query.hasEntity(entityId);
   }

   hasEntityByKey(key, value) {
      return this.query.hasEntity((entity) => entity[key] === value);
   }

   async updateActiveEntity(changes) {
      return this.updateEntity(this.query.getActiveId(), changes);
   }

   async updateEntity(entityId, changes, updateStore = true) {
      return this.httpClient
         .patch(`/${this.version}/${this.entityName}/${entityId}`, changes)
         .then((resp) => {
            if (updateStore) {
               this.store.update(entityId, resp.data);
               if (this.paginate && this.paginator) {
                  this.paginator.refreshCurrentPage();
                  this.pageChanges$.next(this.getPageChangesCombinedObservable());
               }
            }

            return resp.data;
         })

         .catch((error) => {
            this.setError(error);
         });
   }

   createEntityState(entity, setActive = false) {
      const id = Math.min(0, Math.min(...this.store.ids) - 1);

      applyTransaction(() => {
         this.store.add({ ...entity, id });

         if (setActive) {
            this.store.setActive(id);
         }
      });

      return Promise.resolve(id);
   }

   async createEntity(entity, fetchAssociations = false, additionalQueryParams = undefined, addToStore = true) {
      let queryString = '';

      if (additionalQueryParams) {
         queryString = `?${additionalQueryParams.toString()}`;
      }

      return this.httpClient
         .post(`/${this.version}/${this.entityName}${queryString}`, isPlainObject(entity) ? omit(entity, 'id') : entity)
         .then(async (resp) => {
            const obj = resp.data;

            if (Array.isArray(this.collections)) {
               this.collections.forEach((associatedEntityName) => {
                  if (!obj[associatedEntityName]) {
                     obj[associatedEntityName] = [];
                  }
               });
            } else {
               Object.entries(this.collections).forEach(([associatedEntityName]) => {
                  if (!obj[associatedEntityName]) {
                     obj[associatedEntityName] = [];
                  }
               });
            }

            if (fetchAssociations) {
               await this.loadCollections(obj?.id);
            }

            if (Number.isInteger(entity?.id)) {
               this.store.remove(entity.id);
            }

            if (addToStore) {
               this.store.add(obj);

               if (this.paginate && this.paginator) {
                  this.paginator.refreshCurrentPage();
                  this.pageChanges$.next(this.getPageChangesCombinedObservable());
               }
            }

            return obj;
         })
         .catch((error) => this.setError(error));
   }

   async deleteEntityFromCollection(entityId, collectionName, collectionEntityId) {
      return this.httpClient
         .delete(`/${this.version}/${this.entityName}/${entityId}/${collectionName.toLowerCase()}/${collectionEntityId}`)
         .then(() =>
            this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayRemove(collectionName_, collectionEntityId),
            }))
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   async updateEntityCollection(entityId, collectionName, collectionEntityId, changes) {
      return this.httpClient
         .patch(
            `/${this.version}/${this.entityName}/${entityId}/${collectionName.toLowerCase()}/${collectionEntityId}`,
            omit(changes, ['fields', 'ui', 'updatedAt', 'createdAt', 'result', 'id'])
         )
         .then((resp) =>
            this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: collectionEntityId === 'all' ? resp.data : arrayUpdate(collectionName_, collectionEntityId, resp.data),
            }))
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   async deleteEntityFromActiveEntityCollection(collectionName, collectionEntityId) {
      return this.deleteEntityFromCollection(this.query.getActiveId(), collectionName, collectionEntityId);
   }

   deleteCollectionEntityFromState(entityId, collectionName, collectionEntityId) {
      this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
         [collectionName]: arrayRemove(collectionName_, collectionEntityId),
      }));
   }

   updateEntityCollectionState(entityId, collectionName, collectionEntityId, changes) {
      this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
         [collectionName]: arrayUpsert(collectionName_, collectionEntityId, changes),
      }));
   }

   replaceEntityCollectionState(entityId, collectionName, collectionState) {
      this.store.update(entityId, () => ({ [collectionName]: collectionState }));
   }

   setEntityCollectionState(entityId, collectionName, collectionState) {
      applyTransaction(() =>
         collectionState.forEach((collectionEntity) =>
            this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayUpsert(collectionName_, collectionEntity.id, collectionEntity),
            }))
         )
      );
   }

   createEntityCollectionState(entityId, collectionName, collectionEntity) {
      let entity = this.query.getEntity(entityId);

      if (entity && !Array.isArray(entity?.[collectionName])) {
         this.store.update(entityId, { [collectionName]: [] });
         entity = this.query.getEntity(entityId);
      }

      const collection = entity?.[collectionName];
      const id = collection?.length > 0 ? Math.min(0, Math.min(...collection.map((colEntity) => colEntity.id)) - 1) : 0;

      this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
         [collectionName]: arrayAdd(collectionName_, { ...collectionEntity, id }),
      }));
   }

   addEntityCollectionState(entityId, collectionName, additionalItems) {
      const entity = this.query.getEntity(entityId);

      if (entity && !Array.isArray(entity?.[collectionName])) {
         this.store.update(entityId, { [collectionName]: [additionalItems].flat() });
      }

      this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
         [collectionName]: arrayAdd(collectionName_, [additionalItems].flat()),
      }));
   }

   async deleteEntity(entityIdOrArray) {
      let entities;

      if (Array.isArray(entityIdOrArray)) {
         entities = entityIdOrArray;
      } else {
         entities = [entityIdOrArray];
      }

      const promises = [];

      applyTransaction(() => {
         entities.forEach((entityId) => {
            if (entityId <= 0) {
               this.store.remove(entityId);
            } else {
               promises.push(
                  this.httpClient
                     .delete(`/${this.version}/${this.entityName}/${entityId}`)
                     .then(() => (this.paginate ? this.store.setActive(null) : this.store.remove(entityId)))
                     .catch((error) => {
                        this.setError(error);
                     })
               );
            }
         });
      });

      await Promise.all(promises);

      if (this.paginate && this.paginator) {
         this.paginator.refreshCurrentPage();
         this.pageChanges$.next(this.getPageChangesCombinedObservable());
      }
      return true;
   }

   deleteEntityFromState(entityIdOrArray) {
      let entities;

      if (Array.isArray(entityIdOrArray)) {
         entities = entityIdOrArray;
      } else {
         entities = [entityIdOrArray];
      }

      return this.store.remove(entities);
   }

   async deleteActiveEntity() {
      this.deleteEntity(this.query.getActiveId());
   }

   async setActiveEntity(entityUniqueIdentifier, loadCollections = true, refresh = false, loadCollectionParams = undefined) {
      if (this.query.hasActive() && this.query.getActiveId() === entityUniqueIdentifier && !refresh) {
         return undefined;
      }
      if (entityUniqueIdentifier === null) {
         return Promise.resolve(this.store.setActive(entityUniqueIdentifier));
      }
      if ((this.isLoading && !this.isInitial) || this.getError()) {
         return undefined;
      }

      this.isLoading = true;
      this.isInitial = false;

      const keyFields = ['id', 'key', 'slug', 'userName'];

      let entityId;
      let freshlyLoaded = false;

      if (this.query.hasEntity(entityUniqueIdentifier)) {
         const entityIdentifierAsInteger = Number.parseInt(entityUniqueIdentifier, 10);
         if (Number.isNaN(entityIdentifierAsInteger)) {
            entityId = entityUniqueIdentifier;
         } else {
            entityId = entityIdentifierAsInteger;
         }
      } else {
         const entity = this.query.getAll().find((entity_) => keyFields.some((field) => entity_?.[field] === entityUniqueIdentifier));

         if (entity) {
            entityId = entity[this.store.idKey];
         } else {
            this.store.setLoading(true);

            entityId = await this.loadEntity(entityUniqueIdentifier);
            freshlyLoaded = true;
         }
      }

      if (!entityId) {
         this.isLoading = false;
         this.store.setLoading(false);
         return Promise.resolve(Error(`Could not find an entity with identifier ${entityUniqueIdentifier}`));
      }

      if (this.query.hasActive(entityId) && !refresh && loadCollections === false) {
         this.isLoading = false;
         return Promise.resolve(this.getLoading() ? this.store.setLoading(false) : undefined);
      }

      if (this.query.hasEntity(entityId) && refresh && !freshlyLoaded) {
         this.store.setLoading(true);
         await this.loadEntity(entityId);
      }

      if (
         !this.query.hasActive(entityId) &&
         loadCollections &&
         ((Array.isArray(this.collections) && this.collections.length > 0) ||
            (isObject(this.collections) && Object.entries(this.collections.length > 0)))
      ) {
         this.store.setLoading(true);
         await this.loadCollections(entityId, Array.isArray(loadCollections) ? loadCollections : [], loadCollectionParams);
      }

      this.store.setActive(entityId);

      this.isLoading = false;

      return Promise.resolve(this.getLoading() ? this.store.setLoading(false) : undefined);
   }

   setNextEntityActive() {
      return this.store.setActive({ next: true, wrap: false });
   }

   setPreviousEntityActive() {
      return this.store.setActive({ prev: true, wrap: false });
   }

   toggleUIState(entityId, property) {
      if (!this.store.ui) {
         throw new Error(`Entity UI Store is not defined for store ${this.store}`);
      }
      this.store.ui.update(entityId, (state) => ({
         [property]: !state[property],
      }));
   }

   updateUIState(entityId, property, value) {
      if (!this.store.ui) {
         throw new Error(`Entity UI Store is not defined for store ${this.store}`);
      }
      this.store.ui.update(entityId, { [property]: value });
   }

   updateActiveEntityState(changes) {
      return this.store.updateActive(changes);
   }

   updateEntityState(entityId, changes) {
      this.store.update(entityId, changes);
   }

   upsertEntityState(entityId, changes) {
      this.store.upsert(entityId, changes);
   }

   async persistActiveEntityState(relevantKeys = [], queryString = null) {
      const entity = this.query.getActive();

      return Promise.resolve(this.persistEntityState(entity, relevantKeys, queryString));
   }

   async persistEntityState(entity_, relevantKeys = [], queryString = null) {
      let entity;

      entity = omit(entity_, ['createdAt', 'updatedAt', 'kpiFieldType']);

      if (relevantKeys.length > 0) {
         entity = pick(entity, relevantKeys);
      }

      if (!entity?.id || entity?.id <= 0) {
         return Promise.reject('[persistEntityState]: Entity has no id');
      }

      return Promise.resolve(
         this.httpClient
            .patch(`/${this.version}/${this.entityName}/${entity.id}${queryString ?? ''}`, entity)
            .then((resp) => this.store.update(entity.id, resp.data))
            .catch((error) => {
               this.setError(error);
               throw error;
            })
      );
   }

   revertActiveEntityState() {
      const activeId = this.query.getActiveId();
      if (activeId <= 0) {
         this.store.remove(activeId);
      }
   }

   async loadCollection(
      entityId,
      collectionName,
      collectionStoreName = undefined,
      additionalQueryParams = undefined,
      setLoading = true,
      reset = false
   ) {
      if (setLoading) {
         this.store.setLoading(true);
      }

      if (!this.queryParams) {
         await firstValueFrom(this.queryParamsObservable);
      }

      const queryParams = new URLSearchParams(this.queryParams);

      if (additionalQueryParams) {
         const paramsToAdd = new URLSearchParams(additionalQueryParams);

         for (const [key, value] of paramsToAdd.entries()) {
            queryParams.set(key, value);
         }
      }

      return this.httpClient
         .get(`/${this.version}/${this.entityName}/${entityId}/${collectionName.toLowerCase()}?${queryParams.toString()}`)
         .then((resp) => {
            if (collectionStoreName && collectionStoreName !== this.store.storeName) {
               const selectedStore = getEntityStoreByName(collectionStoreName);

               if (!selectedStore) {
                  throw new Error(`Could not finde store ${collectionStoreName}`);
               }

               if (reset) {
                  selectedStore.set(resp.data);
               } else {
                  selectedStore.upsertMany(resp.data, { loading: false });
               }
               return this.store.setLoading(false);
            }

            return applyTransaction(() => {
               this.store.update(entityId, { [collectionName]: resp.data });
               return this.store.setLoading(false);
            });
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async loadCollections(entityId, collectionNames = [], queryParams = undefined) {
      const promises = [];

      if (entityId > 0 && (this.collections?.length > 0 || Object.entries(this.collections)?.length > 0)) {
         applyTransaction(() => {
            if (Array.isArray(this.collections)) {
               this.collections
                  .filter((collectionName) => collectionNames.length === 0 || collectionNames.includes(collectionName))
                  .forEach((collectionName) => {
                     promises.push(this.loadCollection(entityId, collectionName, this.store.storeName, queryParams, false));
                  });
            } else {
               Object.entries(this.collections)
                  .filter(([collectionName]) => collectionNames.length === 0 || collectionNames.includes(collectionName))
                  .forEach(([collectionName, collectionStoreName]) =>
                     promises.push(promises.push(this.loadCollection(entityId, collectionName, collectionStoreName, queryParams, false)))
                  );
            }
         });
      }

      return Promise.all(promises);
   }

   hasActiveEntity(entityId) {
      return this.query.hasActive(entityId);
   }

   addActiveEntity(entityId) {
      return this.store.addActive(entityId);
   }

   removeActiveEntity(entityId) {
      return this.store.removeActive(entityId);
   }

   toggleActiveEntity(entityId) {
      return this.store.toggleActive(entityId);
   }

   setActiveEntities(entityIds) {
      return this.store.setActive(entityIds ?? []);
   }

   async updateEntityFromCollection(entityId, collectionName, collectionEntityId, changes) {
      return this.httpClient
         .patch(`/${this.version}/${this.entityName}/${collectionEntityId}/${collectionName.toLowerCase()}`, changes)
         .then((resp) =>
            this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayUpdate(collectionName_, collectionEntityId, resp.data),
            }))
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   async addEntityToCollection(entityId, collectionName, payload) {
      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${entityId}/${collectionName.toLowerCase()}${this.queryParams ?? ''}`, payload)
         .then((resp) =>
            this.store.update(entityId, ({ [collectionName]: collectionName_ }) => ({
               [collectionName]: arrayAdd(collectionName_, resp.data),
            }))
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   async addEntitiesToCollection(entityId, collectionName, changes) {
      return this.httpClient
         .patch(`/${this.version}/${this.entityName}/${entityId}/${collectionName.toLowerCase()}${this.queryParams ?? ''}`, changes)
         .then((resp) => this.store.update(entityId, { [collectionName]: resp.data }))
         .catch((error) => {
            this.setError(error);
         });
   }

   async setEntitiesInCollection(entityId, collectionName, entities) {
      return this.httpClient
         .put(`/${this.version}/${this.entityName}/${entityId}/${collectionName.toLowerCase()}`, entities)
         .then((resp) => this.store.update(entityId, { [collectionName]: resp.data }))
         .catch((error) => {
            this.setError(error);
         });
   }

   async setAllowedAuditors(entityId, changes) {
      const collectionName = 'allowedauditors';

      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${entityId}/${collectionName.toLowerCase()}${this.queryParams ?? ''}`, changes)
         .then((resp) =>
            this.store.update(entityId, {
               allowedAuditors: resp.data?.allowedAuditors ?? [],
               allowedAuditorGroups: resp.data?.allowedAuditorGroups ?? [],
            })
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   async setAllowedEditors(entityId, changes) {
      const collectionName = 'allowededitors';

      return this.httpClient
         .post(`/${this.version}/${this.entityName}/${entityId}/${collectionName.toLowerCase()}${this.queryParams ?? ''}`, changes)
         .then((resp) =>
            this.store.update(entityId, {
               allowedEditors: resp.data.allowedEditors ?? [],
               allowedEditorGroups: resp.data?.allowedEditorGroups ?? [],
            })
         )
         .catch((error) => {
            this.setError(error);
         });
   }

   setRequestLocale(locale) {
      this.httpClient.defaults.headers.common['Accept-Language'] = locale;
   }

   updateStoreState(key, value) {
      this.store.update({ [key]: value });
   }

   async getHistory(entityId, includeChildObjects = true, level = 'LOG', additionalId = null) {
      const collectionName = 'history';

      // TODO pagination, lazy loading

      let paramsString;

      if (this.queryParams) {
         paramsString = this.queryParams;
      } else {
         paramsString = await firstValueFrom(this.queryParamsObservable);
      }

      const params = new URLSearchParams(paramsString);

      params.set('includeChildObjects', includeChildObjects);
      params.set('level', level);

      if (params.has('from')) {
         params.set('validFrom', params.get('from'));
         params.delete('from');
      }

      if (params.has('to')) {
         params.set('validTo', params.get('to'));
         params.delete('to');
      }

      return this.httpClient
         .get(`/${this.version}/${this.entityName}/${entityId ? entityId.toString().concat('/') : ''}${collectionName.toLowerCase()}?${params ?? ''}`)
         .then(({ data }) => {
            if (['kpiFields', 'taxonomy/businessactivities'].includes(this.store.storeName)) {
               this.store.update(entityId, {
                  history: data,
               });
            } else {
               this.store.update(additionalId, ({ calculations }) => ({
                  calculations: calculations.map((calculation) => ({
                     ...calculation,
                     history: calculation?.id === entityId ? data : null,
                  })),
               }));
            }

            return this.historyService.setHistoryReference(this.store.storeName, entityId, additionalId);
         })
         .catch((error) => {
            this.setError(error);
         });
   }

   async persistEntity(entityOrId) {
      let entity;

      if (Number.isNaN(parseInt(entityOrId, 10))) {
         entity = entityOrId;
      } else {
         entity = this.query.getEntity(entityOrId);
      }

      if (!entity?.id || entity?.id <= 0) {
         return this.createEntity(entity);
      }

      return applyTransaction(() =>
         this.httpClient
            .patch(`/${this.version}/${this.entityName}/${entity.id}`, entity)
            .then(({ data }) => this.store.update(entity.id, data))
            .catch((error) => {
               this.setError(error);
            })
      );
   }

   unsubscribe() {
      if (this.allEntitiesAbortController) {
         // this.allEntitiesAbortController.abort();
      }

      // this.pendingGetAllEntitiesRequests.clear();

      if (this.getEntitiesObservable && !this.getEntitiesObservable?.closed) {
         this.getEntitiesObservable.unsubscribe();
         this.getEntitiesObservable = undefined;
      }
   }
}
