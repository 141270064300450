import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { activeOrganisationId$ } from 'context/OrganisationContext/query';
import { context$ as periodContext$ } from 'context/PeriodContext/query';
import { includeSubsidiaries$ } from 'context/UserContext/query';
import { parseISO } from 'date-fns';
import { groupBy, isEqual, omit, uniqWith } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { entities$ as alignmentResults$ } from 'state/TaxonomyAlignmentResults/query';
import { entities$ as contributions$ } from 'state/TaxonomyContributions/query';
import { entities$ as objectives$ } from 'state/TaxonomyObjectives/query';
import { query as uiQuery } from 'state/UI/query';
import { ApprovalStatus, TaxonomyContributionType } from 'utils/enum';
import { businessActivitiesStore, responsesStore } from './store';

const nestCriteria = (items, id = undefined) =>
   items
      .filter((item) => item?.parent?.id === id)
      .sort((a, b) => (a?.key && b?.key ? a.key.localeCompare(b.key) : a.id - b.id))
      .map((item) => ({ ...item, criteria: nestCriteria(items, item.id) }));

export const query = createEntityQuery(businessActivitiesStore, { sortBy: 'updatedAt', sortByOrder: Order.DESC });

export const activeOrganisationAssignmentId$ = query.select('activeOrganisationAssignment');

export const responsesQuery = createEntityQuery(responsesStore);

export const assignedTaxonomyActivities$ = query.selectActive(({ taxonomyActivityAssignments }) => taxonomyActivityAssignments);
export const suggestedTaxonomyActivities$ = query.selectActive(({ suggestedtaxonomyactivities }) => suggestedtaxonomyactivities);

export const filter$ = query.select('filter');

export const allResponseEntities$ = responsesQuery.selectAll();

export const allBusinessActivities$ = query.selectAll();

export const loading$ = query.selectLoading();

export const activeEntity$ = combineQueries([
   query.selectActive(),
   allResponseEntities$,
   periodContext$,
   activeOrganisationId$,
   alignmentResults$,
   contributions$,
   activeOrganisationAssignmentId$,
]).pipe(
   map(([activeBusinessActivity, responses, periodContext, activeOrganisationId, alignmentResults, contributions, orgAssignmentId]) =>
      activeBusinessActivity
         ? {
              ...omit(activeBusinessActivity, ['alignmentresults']),
              ...omit(
                 (activeBusinessActivity?.organisationAssignments ?? []).find((orgAssignment) =>
                    orgAssignmentId ? orgAssignment.id === orgAssignmentId : orgAssignment?.organisation?.id === activeOrganisationId
                 ),
                 ['id']
              ),
              taxonomyActivityAssignments: Array.isArray(activeBusinessActivity?.taxonomyActivityAssignments)
                 ? activeBusinessActivity.taxonomyActivityAssignments
                      .filter(
                         (taxActivityAssignment) =>
                            taxActivityAssignment?.organisation?.id === activeOrganisationId &&
                            parseISO(taxActivityAssignment?.validFrom) >= parseISO(periodContext?.from) &&
                            parseISO(taxActivityAssignment?.validTo) <= parseISO(periodContext?.to)
                      )
                      .map((taxActivityAssignment) => ({
                         ...taxActivityAssignment,
                         answerableCriteriaCount: Array.isArray(taxActivityAssignment?.taxonomyActivity?.criteria)
                            ? taxActivityAssignment?.taxonomyActivity?.criteria.filter(
                                 (criterion) => criterion?.activity?.id === taxActivityAssignment.taxonomyActivity?.id && criterion?.answerable
                              ).length
                            : 0,
                         answeredCriteriaCount: Array.isArray(taxActivityAssignment?.taxonomyActivity?.criteria)
                            ? taxActivityAssignment?.taxonomyActivity?.criteria.filter(
                                 (criterion) =>
                                    criterion?.activity?.id === taxActivityAssignment.taxonomyActivity?.id &&
                                    criterion?.answerable &&
                                    responses.some(
                                       (response) =>
                                          response?.criterion?.id === criterion?.id &&
                                          response?.businessActivity?.id === activeBusinessActivity?.id &&
                                          response?.organisation?.id === activeOrganisationId &&
                                          parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                                          parseISO(response?.validTo) <= parseISO(periodContext?.to) &&
                                          !!response?.explanation
                                    )
                              ).length
                            : 0,
                      }))
                 : [],
              alignmentResult:
                 activeBusinessActivity?.alignmentresults?.[0] ??
                 alignmentResults.find(
                    (ar) =>
                       ar?.businessActivity?.id === activeBusinessActivity?.id &&
                       ar?.organisation?.id === activeOrganisationId &&
                       parseISO(ar?.validFrom) >= parseISO(periodContext?.from) &&
                       parseISO(ar?.validTo) <= parseISO(periodContext?.to)
                 ),
              contributions: Array.isArray(activeBusinessActivity?.contributions)
                 ? activeBusinessActivity.contributions
                 : contributions.filter(
                      (c) =>
                         c?.businessActivity?.id === activeBusinessActivity?.id &&
                         c?.organisation?.id === activeOrganisationId &&
                         parseISO(c?.validFrom) >= parseISO(periodContext?.from) &&
                         parseISO(c?.validTo) <= parseISO(periodContext?.to)
                   ),
           }
         : undefined
   )
);

export const responses$ = combineQueries([
   allResponseEntities$,
   objectives$,
   assignedTaxonomyActivities$,
   activeEntity$,
   periodContext$,
   activeOrganisationId$,
]).pipe(
   map(([responses, objectives, assignedTaxonomyActivities, activeBusinessActivity, periodContext, activeOrganisationId]) =>
      responses && objectives && assignedTaxonomyActivities && activeBusinessActivity
         ? objectives.map((objective) => ({
              ...objective,
              taxonomyActivityAssignments: assignedTaxonomyActivities
                 ? assignedTaxonomyActivities.map((relation) => ({
                      ...relation,
                      taxonomyActivity: {
                         ...relation.taxonomyActivity,
                         ...(objective.status === TaxonomyContributionType.DO_NO_SIGNIFICANT_HARM
                            ? {
                                 criteriaByObjective: groupBy(
                                    nestCriteria(
                                       Array.isArray(relation?.taxonomyActivity?.criteria)
                                          ? uniqWith(
                                               relation.taxonomyActivity.criteria.filter(
                                                  (criterion) =>
                                                     criterion?.activity?.id === relation.taxonomyActivity?.id &&
                                                     ((criterion?.objective?.id === objective?.id &&
                                                        criterion.contributionType.code !== TaxonomyContributionType.DO_NO_SIGNIFICANT_HARM) ||
                                                        (criterion?.contributionType?.code === TaxonomyContributionType.DO_NO_SIGNIFICANT_HARM &&
                                                           objective?.id === 0 &&
                                                           !(
                                                              Array.isArray(activeBusinessActivity.objectives) &&
                                                              activeBusinessActivity.objectives.length === 1 &&
                                                              activeBusinessActivity.objectives.find(
                                                                 (anObjective) => anObjective.id === criterion?.objective?.id
                                                              )
                                                           )))
                                               ),
                                               (a, b) =>
                                                  a.desc === b.desc &&
                                                  a.key === b.key &&
                                                  a.answerable === b.answerable &&
                                                  a.level === b.level &&
                                                  a.objective?.id === b.objective?.id &&
                                                  a.answerable === true // need to do that so that we don't filter 2 criteria, where the children are distinct, but the parents are equal. In that case we would not show the children
                                            ).map((criterion) => ({
                                               ...criterion,
                                               response: responses.find(
                                                  (response) =>
                                                     response?.criterion?.id === criterion?.id &&
                                                     response?.businessActivity?.id === activeBusinessActivity?.id &&
                                                     response?.organisation?.id === activeOrganisationId &&
                                                     parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                                                     parseISO(response?.validTo) <= parseISO(periodContext?.to)
                                               ),
                                            }))
                                          : []
                                    ),
                                    (c) => c.objective.id
                                 ),
                              }
                            : {
                                 criteria: Array.isArray(relation?.taxonomyActivity?.criteria)
                                    ? nestCriteria(
                                         relation.taxonomyActivity.criteria
                                            .filter(
                                               (criterion) =>
                                                  criterion?.activity?.id === relation.taxonomyActivity?.id &&
                                                  ((criterion?.objective?.id === objective?.id &&
                                                     criterion.contributionType.code !== TaxonomyContributionType.DO_NO_SIGNIFICANT_HARM) ||
                                                     (criterion?.contributionType?.code === TaxonomyContributionType.DO_NO_SIGNIFICANT_HARM &&
                                                        objective?.id === 0 &&
                                                        !(
                                                           Array.isArray(activeBusinessActivity.objectives) &&
                                                           activeBusinessActivity.objectives.length === 1 &&
                                                           activeBusinessActivity.objectives.find(
                                                              (anObjective) => anObjective.id === criterion?.objective?.id
                                                           )
                                                        )))
                                            )
                                            .map((criterion) => ({
                                               ...criterion,
                                               response: responses.find(
                                                  (response) =>
                                                     response?.criterion?.id === criterion?.id &&
                                                     response?.businessActivity?.id === activeBusinessActivity?.id &&
                                                     response?.organisation?.id === activeOrganisationId &&
                                                     parseISO(response?.validFrom) >= parseISO(periodContext?.from) &&
                                                     parseISO(response?.validTo) <= parseISO(periodContext?.to)
                                               ),
                                            }))
                                      )
                                    : [],
                              }),
                      },
                   }))
                 : null,
           }))
         : []
   ),
   distinctUntilChanged(isEqual)
);

export const someOpen$ = activeEntity$.pipe(
   map((activeBusinessActivity) => activeBusinessActivity?.alignmentResult?.type?.code === 'OP'),
   distinctUntilChanged()
);

export const entities$ = combineQueries([
   allBusinessActivities$,
   alignmentResults$,
   contributions$,
   periodContext$,
   activeOrganisationId$,
   filter$,
   uiQuery.select('searchBusinessActivityList'),
   uiQuery.select('searchBusinessActivityTaxonomyActivity'),
   uiQuery.select('searchBusinessActivityResultType'),
   uiQuery.select('searchBusinessActivityStatus'),
   includeSubsidiaries$,
]).pipe(
   map(
      ([
         businessActivities,
         alignmentResults,
         contributions,
         periodContext,
         activeOrganisationId,
         filter,
         searchTerm,
         filteredTaxonomyActivities,
         filteredResultTypes,
         filteredStati,
         includeSubsidiaries,
      ]) =>
         businessActivities && periodContext
            ? businessActivities
                 .filter(
                    (businessActivity) =>
                       (searchTerm
                          ? (businessActivity?.name ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                            (businessActivity?.naceClass?.name ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                            (businessActivity?.naceClass?.code ?? '').toLowerCase().includes(searchTerm.toLowerCase())
                          : true) &&
                       (Array.isArray(filteredTaxonomyActivities) &&
                       filteredTaxonomyActivities.length > 0 &&
                       Array.isArray(businessActivity?.taxonomyActivityAssignments)
                          ? businessActivity.taxonomyActivityAssignments.some((taxActivityAssignment) =>
                               filteredTaxonomyActivities.map((tA) => tA?.id).includes(taxActivityAssignment?.taxonomyActivity?.id)
                            )
                          : true)
                 )
                 .flatMap((businessActivity) =>
                    Array.isArray(businessActivity?.organisationAssignments)
                       ? businessActivity.organisationAssignments
                            .filter((orgAssignment) => (includeSubsidiaries ? true : orgAssignment.organisation.id === activeOrganisationId))
                            .map((orgAssignment) => ({
                               ...omit(businessActivity, ['organisationAssignments', 'taxonomyActivityAssignments']),
                               ...omit(orgAssignment, ['id']),
                               assignmentId: orgAssignment.id,
                               taxonomyActivityAssignments: Array.isArray(businessActivity?.taxonomyActivityAssignments)
                                  ? businessActivity.taxonomyActivityAssignments.filter(
                                       (taxActivityAssignment) => taxActivityAssignment?.organisation?.id === orgAssignment.organisation.id
                                    )
                                  : [],
                               alignmentResult: alignmentResults.find(
                                  (ar) =>
                                     ar?.businessActivity?.id === businessActivity?.id &&
                                     ar?.organisation?.id === orgAssignment?.organisation?.id &&
                                     parseISO(ar?.validFrom) >= parseISO(periodContext?.from) &&
                                     parseISO(ar?.validTo) <= parseISO(periodContext?.to)
                               ),
                               contributions: contributions.filter(
                                  (c) =>
                                     c?.businessActivity?.id === businessActivity?.id &&
                                     c?.organisation?.id === orgAssignment?.organisation?.id &&
                                     parseISO(c?.validFrom) >= parseISO(periodContext?.from) &&
                                     parseISO(c?.validTo) <= parseISO(periodContext?.to)
                               ),
                            }))
                       : []
                 )
                 .filter(
                    (businessActivity) =>
                       (Array.isArray(filteredStati) && filteredStati.length > 0 ? filteredStati.includes(businessActivity?.status) : true) &&
                       (Array.isArray(filteredResultTypes) && filteredResultTypes.length > 0
                          ? filteredResultTypes.some((resultType) => resultType.id === businessActivity?.alignmentResult?.type?.id)
                          : true) &&
                       (filter === 'UNARCHIVED' ? businessActivity?.status !== ApprovalStatus.ARCHIVED : true)
                 )
            : []
   ),
   distinctUntilChanged(isEqual)
);

export const unassignedEntities$ = combineQueries([allBusinessActivities$, activeOrganisationId$]).pipe(
   map(([businessActivities, activeOrganisationId]) =>
      businessActivities && activeOrganisationId
         ? businessActivities.filter((businessActivity) =>
              Array.isArray(businessActivity?.organisationAssignments)
                 ? !businessActivity.organisationAssignments.some((orgAssignment) => orgAssignment.organisation?.id === activeOrganisationId)
                 : true
           )
         : []
   ),
   distinctUntilChanged(isEqual)
);

export const allResponses$ = combineQueries([query.selectActiveId(), allResponseEntities$, activeOrganisationId$, periodContext$]).pipe(
   map(([activeBusinessActivityId, responses, activeOrganisationId, periodContext]) =>
      responses.filter(
         (response) =>
            response.organisation.id === activeOrganisationId &&
            response.validFrom === periodContext?.from &&
            response.validTo === periodContext?.to &&
            response.businessActivity?.id === Number.parseInt(activeBusinessActivityId, 10)
      )
   )
);

export const usedTaxonomyActivities$ = query.select('assignedTaxonomyActivities');

export const activeCriterionId$ = query.select('activeCriterion');

export const statistics$ = query.select('statistics');

export const sort$ = query.select('sort');
