import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { activeOrganisationId$, parentIds$, subsidiaryIds$ } from 'context/OrganisationContext/query';
import { context$ as periodContext$ } from 'context/PeriodContext/query';
import { includeSubsidiaries$ } from 'context/UserContext/query';
import { parseISO } from 'date-fns';
import { isEqual, omit, sortBy } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs';
import { activeEntityId$ } from 'state/KPI/query';
import { flatScopedEntitiesResult$ } from 'state/KPISet/query';
import { entities$ as kpiValues$, entitiesWithFlattenedItems$ as kpiValuesOrItems$ } from 'state/KPIValue/query';
import store from './store';

const theQuery = createEntityQuery(store, {
   sortBy: 'position',
   sortByOrder: Order.ASC,
});

theQuery.createUIQuery();

export const query = theQuery;
export const uiquery = theQuery.ui;

const allEntities$ = theQuery.selectAll();
const allEntitiesUI$ = uiquery.selectAll();

export const entitiesTypeTableColumn$ = allEntities$.pipe(map((kpiFields) => kpiFields.filter((kpiField) => !!kpiField?.fieldGroupId)));

export const entitiesWithoutValues$ = combineQueries([
   allEntities$,
   allEntitiesUI$,
   flatScopedEntitiesResult$,
   subsidiaryIds$,
   parentIds$,
   includeSubsidiaries$,
   activeOrganisationId$,
]).pipe(
   map(([kpiFields, , scopingResults, subsidiaryIds = [], parentIds = [], includeSubsidiaries, activeOrganisationId]) =>
      kpiFields
         .filter((kpiField) => !kpiField?.fieldGroupId)
         .map((kpiField) => ({
            ...scopingResults.find((result) => result.type === 'kpiField' && result.id === kpiField.id),
            ...kpiField,
            ui: uiquery.getEntity(kpiField.id),
            metas: Array.isArray(kpiField?.metas) ? kpiField.metas.map((meta) => ({ ...meta, name: meta.value })) : [],
            columns: sortBy(
               kpiFields.filter(
                  ({ organisationId, fieldGroupId }) =>
                     fieldGroupId === kpiField.id &&
                     (includeSubsidiaries
                        ? subsidiaryIds.includes(organisationId) || !organisationId || parentIds.includes(organisationId)
                        : !organisationId || organisationId === activeOrganisationId || parentIds.includes(organisationId))
               ),
               (o) => o.position
            ),
            rows: Array.isArray(kpiField?.rows)
               ? kpiField.rows
                    .filter(({ organisationId }) =>
                       includeSubsidiaries
                          ? subsidiaryIds.includes(organisationId) || !organisationId
                          : !organisationId || organisationId === activeOrganisationId
                    )
                    .map((row) => ({
                       ...row,
                       formulas: kpiFields
                          .filter(
                             (field) =>
                                field?.fieldGroupId === kpiField.id && Array.isArray(field?.rows) && field.rows.some(({ rowId }) => rowId === row.id)
                          )
                          .flatMap(({ rows, slug }) =>
                             rows
                                .filter(({ rowId }) => rowId === row.id)
                                .flatMap(({ formulas = [], kpiValuesCount, kpiValuesSum }) =>
                                   formulas.map((formula) => ({ ...formula, field: slug, kpiValuesSum, kpiValuesCount }))
                                )
                          ),
                       values: kpiFields
                          .filter(
                             (field) =>
                                field?.fieldGroupId === kpiField.id && Array.isArray(field?.rows) && field.rows.some(({ rowId }) => rowId === row.id)
                          )
                          .flatMap(({ rows, slug }) =>
                             rows
                                .filter(({ rowId }) => rowId === row.id)
                                .flatMap(({ values, kpiValuesCount, kpiValuesSum, kpiFieldId }) =>
                                   (values ?? []).map((value) => ({
                                      ...value,
                                      kpiValuesSum,
                                      kpiValuesCount,
                                      kpiValueId: value.id,
                                      kpiFieldId,
                                      field: slug,
                                   }))
                                )
                          ),
                    }))
               : [],
         }))
   ),
   distinctUntilChanged(isEqual)
);

export const entities$ = combineQueries([
   allEntities$,
   allEntitiesUI$,
   activeEntityId$,
   kpiValues$,
   periodContext$,
   activeOrganisationId$,
   subsidiaryIds$,
   parentIds$,
   includeSubsidiaries$,
]).pipe(
   map(([kpiFields, , activeKPIId, kpiValues, periodContext, activeOrganisationId, subsidiaryIds = [], parentIds = [], includeSubsidiaries]) =>
      activeKPIId && kpiValues && periodContext && activeOrganisationId && subsidiaryIds
         ? kpiFields
              .filter((kpiField) => !kpiField?.fieldGroupId)
              .map((kpiField) => ({
                 ...kpiField,
                 columns: sortBy(
                    kpiFields.filter(
                       ({ organisationId, fieldGroupId }) =>
                          fieldGroupId === kpiField.id &&
                          (includeSubsidiaries
                             ? subsidiaryIds.includes(organisationId) || !organisationId || parentIds.includes(organisationId)
                             : !organisationId || organisationId === activeOrganisationId || parentIds.includes(organisationId))
                    ),
                    (o) => o.position
                 ),
                 rows: Array.isArray(kpiField?.rows)
                    ? kpiField.rows
                         .filter(({ organisationId }) =>
                            includeSubsidiaries
                               ? subsidiaryIds.includes(organisationId) || !organisationId
                               : !organisationId || organisationId === activeOrganisationId
                         )
                         .map((row) => ({
                            ...row,
                            values: kpiFields
                               .filter(
                                  (field) =>
                                     field?.fieldGroupId === kpiField.id &&
                                     Array.isArray(field?.rows) &&
                                     field.rows.some(({ rowId }) => rowId === row.id)
                               )
                               .flatMap(({ rows, slug }) =>
                                  rows
                                     .filter(({ rowId }) => rowId === row.id)
                                     .flatMap(({ values, kpiValuesCount, kpiValuesSum, kpiFieldId }) =>
                                        (values ?? []).map((value) => ({
                                           ...value,
                                           kpiValuesSum,
                                           kpiValuesCount,
                                           kpiValueId: value.id,
                                           kpiFieldId,
                                           field: slug,
                                        }))
                                     )
                               ),
                         }))
                    : [],
                 values:
                    kpiField?.kpiContent?.kpiSub?.kpi?.id === Number.parseInt(activeKPIId, 10)
                       ? kpiValues.filter(
                            (kpiValue) =>
                               (kpiValue.sourceKpiFieldId ?? kpiValue.kpiFieldId) === kpiField?.id &&
                               (includeSubsidiaries ? true : kpiValue.organisationId === activeOrganisationId) &&
                               parseISO(kpiValue.from) >= parseISO(periodContext?.from) &&
                               parseISO(kpiValue.to) <= parseISO(periodContext?.to)
                         )
                       : [],
                 ui: uiquery.getEntity(kpiField.id),
              }))
         : []
   ),
   distinctUntilChanged(isEqual)
);

export const activeRow$ = query.select('activeRow');

export const activeEntity$ = combineQueries([
   query.selectActive(),
   uiquery.selectActive(),
   kpiValues$,
   kpiValuesOrItems$,
   periodContext$,
   activeOrganisationId$,
   includeSubsidiaries$,
   subsidiaryIds$,
]).pipe(
   map(([kpiField, uiEntity, kpiValues, kpiValuesOrItems, periodContext, activeOrganisationId, includeSubsidiaries, subsidiaryIds]) =>
      kpiField
         ? {
              ...kpiField,
              values: kpiValues
                 .filter(
                    (kpiValue) =>
                       kpiValue.kpiFieldId === kpiField?.id &&
                       kpiValue.organisationId === activeOrganisationId &&
                       parseISO(kpiValue.from) >= parseISO(periodContext?.from) &&
                       parseISO(kpiValue.to) <= parseISO(periodContext?.to)
                 )
                 .map((kpiValue) => omit(kpiValue, ['proportionateValue'])),
              valuesOrItems: kpiValuesOrItems
                 .filter(
                    (kpiValueOrItem) =>
                       kpiValueOrItem.kpiFieldId === kpiField?.id &&
                       (includeSubsidiaries ? true : kpiValueOrItem.organisationId === activeOrganisationId) &&
                       (kpiValueOrItem?.from ?? kpiValueOrItem?.date) >= parseISO(periodContext?.from) &&
                       (kpiValueOrItem?.to ?? kpiValueOrItem?.date) <= parseISO(periodContext?.to)
                 )
                 .map((kpiValueOrItem) => omit(kpiValueOrItem, ['proportionateValue'])),
              subsidiaryValues: kpiValues
                 .filter(
                    (kpiValue) =>
                       kpiValue.kpiFieldId === kpiField?.id &&
                       subsidiaryIds.includes(kpiValue.organisationId) &&
                       kpiValue.organisationId !== activeOrganisationId &&
                       parseISO(kpiValue.from) >= parseISO(periodContext?.from) &&
                       parseISO(kpiValue.to) <= parseISO(periodContext?.to)
                 )
                 .map((kpiValue) => ({ ...omit(kpiValue, ['proportionateValue', 'value']), value: kpiValue.proportionateValue })),
              ui: uiEntity,
           }
         : null
   )
);

export const activeMeta$ = query.select('activeMeta');

export const activeFieldGroup$ = query.select('activeFieldGroup');

export const loading$ = query.selectLoading();
