import { combineQueries, createEntityQuery, Order } from '@datorama/akita';
import { locale$ } from 'context/UserContext/query';
import { localeIncludes } from 'locale-includes';
import { map } from 'rxjs';
import { query as UIQuery } from 'state/UI/query';
import store from './store';

export const query = createEntityQuery(store, {
   sortBy: 'id',
   sortByOrder: Order.ASC,
});

export const entities$ = query.selectAll().pipe(
   map((organisations) =>
      organisations.map((organisation) => ({
         ...organisation,
         label: organisation.code ? `(${organisation.code}) ${organisation.name}` : organisation.name,
         value: organisation.id,
      }))
   )
);

export const filteredEntities$ = combineQueries([entities$, UIQuery.select('searchOrganizationList'), locale$]).pipe(
   map(([entities, searchTerm, userLocale]) =>
      entities.filter(
         ({ name, code }) =>
            !searchTerm ||
            localeIncludes(name ?? '', searchTerm ?? '', {
               locales: userLocale ?? 'en',
               usage: 'search',
               sensitivity: 'base',
               ignorePunctuation: true,
            }) ||
            localeIncludes(code ?? '', searchTerm ?? '', {
               locales: userLocale ?? 'en',
               usage: 'search',
               sensitivity: 'base',
               ignorePunctuation: true,
            })
      )
   )
);

export const activeEntity$ = query.selectActive();

export const entityTree$ = entities$.pipe(
   map((organisations) => nestOrganisations(organisations ?? []).sort((a, b) => a.children.length - b.children.length))
);

export const statistics$ = query.select('statistics');
export const loading$ = query.selectLoading();

export const entitiesBeingDeleted$ = query.selectAll({
   filterBy: (entity) => !!entity?.deleteOperationStatus,
});

export const sort$ = query.select('sort');

function nestOrganisations(items, id = null) {
   // we want to find the root of the tree. In case of rights on individual orgs, that is not always possible
   // we need to find either an org that has no parents or an org that has a parent that we do not know
   if (id === null) {
      const itemsWithoutParents = items.filter((item) => (item?.parents ?? []).length === 0);

      // if only one org has no parent, we can determine the tree
      if (itemsWithoutParents.length === 1) {
         return itemsWithoutParents.map((item) => ({ ...item, children: nestOrganisations(items, item.id) }));
      }

      const itemsWithoutKnownParents = items.filter((item) => (item?.parents ?? []).some((parent) => !items.find((i) => i.id === parent.id)));

      // of only one item has an unknown parent, we can determine the tree
      if (itemsWithoutKnownParents.length === 1) {
         return itemsWithoutKnownParents.map((item) => ({ ...item, children: nestOrganisations(items, item.id) }));
      }
   }

   return items
      .filter((item) => (item?.parents ?? []).find((parent) => parent?.id === id))
      .map((item) => ({ ...item, children: nestOrganisations(items, item.id) }));
}
